.itemContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  border: solid 0.5px rgb(233, 233, 233);
  border-radius: 4px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.column {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.name {
  padding-left: 10px;
  color: rgb(56, 56, 56);
  font-weight: bold;
}

.occupation {
  padding-left: 10px;
  color: rgb(56, 56, 56);
  padding-bottom: 10px;
}

.bio {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  word-wrap: break-word;
  white-space: pre-line;
  width: 85%;
}

.nameAndReadFullContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 18px;
}

.readMore {
  font-size: 14px;
}

.simpleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
