.card {
    width: 80px;
    height: 80px;
    margin: 10px;
    transition: 0.3s;
    box-shadow: 0 2px 3px gray;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .card:hover {
    cursor: pointer;
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
  }
  
  .card:active {
    cursor: pointer;
    opacity: 0.5;
  }
  
.center {
  flex: 1;
  text-align: center;
  font-weight: bold;
  font-size: small;
}



@media (max-width: 499px) {}
  
@media (max-width: 599px) {
  .card {
    width: 45px;
    height: 45px;
  }

  .center {
    font-size: x-small;
  }
}