.settingRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px solid rgb(234, 233, 233);
  padding: 20px;
  border-radius: 0px;
}

.settingDescription {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settingDescriptionDisabled {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.6;
}

.settingTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
