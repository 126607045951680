.toolbar {
  height: 56px;
  width: 100%;
  position: fixed; /**does not move **/
  top: 0px; /***/
  left: 0;

  background-color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 14px;
  box-sizing: border-box;
  z-index: 117; /** **/
}

.toolbar nav {
  height: 100%;
}

.logo {
  height: 42px;
  padding-right: 9px;
}

.logoText {
  height: 33px;
  align-items: center;
  padding-bottom: 2px;
}

.title {
  color: white;
  font-size: 14px;
  align-items: center;
}

.nine {
  color: black;
  font-style: italic;
}

@media (max-width: 950px) {
  .nine {
    display: none;
  }
}

@media (max-width: 499px) {
  .destopOnly {
    display: none;
  }
}

@media (min-width: 500px) {
  .mobileOnly {
    display: none;
  }
}
.menuButton:hover {
  cursor: pointer;
}

.providerName {
  flex: 1;
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 14px;
  padding-left: 13px;
  align-items: center;
  padding-bottom: 1px;
  transition-timing-function: ease-in;
  transition: 0.25s;
  text-decoration: none;
}

.margin {
  padding-left: 190px;
  transition-timing-function: ease-out;
  transition: 0.25s;
}

/** navigation items **/

.navigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

@media (max-width: 700px) {
  .tablet {
    display: none;
  }
}

/** end navigation items **/
