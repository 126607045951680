.itemContainer {
  display: flex;
  flex-direction: row;
}

.categoryContainer {
  display: flex;
  flex-direction: row;
}

.title {
  font-size: medium;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.category {
  font-size: small;
  color: blue;
}

.provider {
  font-size: small;
  color: green;
}

.searchIcon {
  padding-right: 5px;
  padding-top: 0px;
}

@media (max-width: 699px) {
  .title {
    font-size: small;
  }

  .category {
    font-size: smaller;
    color: blue;
  }

  .provider {
    font-size: smaller;
    color: green;
  }

  .searchIcon {
    padding-right: 3px;
    padding-top: 2px;
  }
}
