.container {
  width: 100%;
  background: rgb(225, 233, 237);
  background: linear-gradient(
    0deg,
    rgb(232, 238, 240) 1%,
    rgba(255, 255, 255, 1) 24%,
    rgba(247, 254, 255, 1) 36%,
    rgba(235, 246, 248, 1) 100%
  );
  padding-top: 80px;
  padding-bottom: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerAreYou {
  background: rgb(225, 233, 237);
  background: linear-gradient(
    0deg,
    rgb(232, 238, 240) 1%,
    rgba(255, 255, 255, 1) 24%,
    rgba(247, 254, 255, 1) 36%,
    rgba(235, 246, 248, 1) 100%
  );
  flex-wrap: wrap;
  padding-top: 80px;
  padding-bottom: 80px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.containerAreYou2 {
  background-color: white;
  flex-wrap: wrap;
  padding-top: 80px;
  padding-bottom: 80px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.slideContainer {
  width: 90%;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 80px;
  padding-bottom: 40px;

  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  border: none;
  outline: none;
}

.slideContainerProvider {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;

  border-radius: 0px;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  border: none;
  outline: none;
}

.slideWrapContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  outline: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  border: 0px solid white;
}

/* On mouse-over, add a deeper shadow */
.slideContainer:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.textContainer {
  max-width: 400px;
  padding-left: 1%;
}

.imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.title {
  padding-bottom: 30px;
  font-family: Inter;
  font-size: 34px;
  color: rgb(19, 19, 19);
}

.brand {
  text-transform: none;
}

.subTitle {
  padding-bottom: 15px;
  font-family: Inter;
  font-size: 23px;
  color: rgb(41, 41, 41);
}

.text {
  padding-bottom: 25px;
  font-size: 18px;
}

.image {
  height: auto;
}

.imageProvider {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.sectionTitle {
  width: 100%;
  display: block;
}

@media (max-width: 599px) {
  .floatingTitle1 {
    padding-top: 10px;
  }

  .textContainer {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .imgContainer {
    width: 100%;
  }

  .slideContainer {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
    padding-bottom: 20px;
  }

  
  .image {
    height: 270px;
  }

  .containerAreYou {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .containerAreYou2 {
    padding-top: 40px;
    padding-bottom: 40px;
    
  }

  .container {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .rowButtonSlideContainer {
    margin-left: 5px;
    margin-right: 5px;
  }

  .imageProvider {
    border-radius: 0px;
  }
}

.activeDot {
  border-radius: 40px;
  height: 20px;
  width: 20px;
  border: 0px;
  background-color: #3e8cab;
  outline: none;
}

.dot {
  border-radius: 40px;
  height: 20px;
  width: 20px;
  border: 0px;
  background-color: black;
  outline: none;
}

.dot:hover {
  background-color: #ccc;
}

.dot:active {
  background-color: #ccc;
}

.dotContainer {
  width: 100%;
  text-align: center;
}


.slideButton {
  outline: none;
  margin: 0px;
  border: 1px solid #ccc;
  background: none;
  color: rgb(100, 100, 100);
  border-radius: 30px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.slideButton:hover {
  color: rgb(31, 132, 195);
  border-color:  rgb(31, 132, 195);
}

.slideButton:active {
  color: black;
  border-color: black;
}

.sliderButtonContainer {
  display: flex;
  align-items: center;
}

.rowButtonSlideContainer {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
}