.featured {
  width: 100px;
  height: 120px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: rgb(216, 235, 239);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.featuredText {
  justify-items: center;
  transform: rotate(-40deg);
}

.itemContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 0.5px rgb(233, 233, 233);
  border-radius: 4px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
}

.column {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.readMore {
  font-size: 14px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.simpleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column1 {
  display: flex;
  width: 20%;
  justify-content: center;
}

.desc {
  width: 90px;
}

.column2 {
  padding-left: 5px;
  padding-right: 15px;
  width: 50%;
}

.column3 {
  padding-right: 15px;
  flex-flow: column nowrap;
  width: 30%;
}

.titleSide {
  font-weight: bold;
  padding-right: 10px;
}

.titleTop {
  font-weight: bold;
  padding-right: 5px;

  font-style: normal;
  display: flex;
  align-items: center;
}

.subInfoBanner {
  font-style: italic;
  margin-top: 5px;
  padding: 0px;
  color: rgb(45, 45, 45);
  font-size: 13px;
}

@media screen and (max-width: 500px) {
  .column1 {
    display: none;
  }
  .column2 {
    width: 100%;
  }
  .column3 {
    display: none;
  }
}

.imageSelectorContainer {
  max-width: 186px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.promoContainer {
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
}

.phonePic {
  width: auto;
  height: 160px;
}
