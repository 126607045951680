.container {
  padding: 0px;
  border-radius: 5px;
  width: 100%;
  min-height: 150px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.title {
  padding-left: 34px;
  padding-bottom: 13px;
  font-family: "inter";
  font-size: larger;
  color: rgb(8, 105, 147);
}

.pageButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin-left: 2px;
  margin-right: 2px;
}

@media (max-width: 599px) {
  .title {
    padding-left: 0px;
    padding-top: 5px;
    font-size: medium;
  }

  .container {
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
