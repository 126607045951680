.row {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
}

.error {
  border: 1px solid red;
}

.margin {
  width: 61%;
  margin: auto;
}

/* Create four equal columns */
.column {
  flex: 47%;
  padding-left: 5px;
  padding-right: 5px;
}

.street {
  flex: 20%;
  padding-left: 5px;
  padding-right: 5px;
}

.route {
  flex: 60%;
  padding-left: 5px;
  padding-right: 5px;
}

.suite {
  flex: 20%;
  padding-left: 5px;
  padding-right: 5px;
}

.sideBySide {
  display: flex;
  width: 75%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .column {
    flex: 50%;
  }

  .street {
    flex: 20%;
  }

  .route {
    flex: 60%;
  }
  .suite {
    flex: 20%;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 500px) {
  .column {
    flex-direction: column;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .street {
    flex-direction: column;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .route {
    flex-direction: column;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .suite {
    flex-direction: column;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .row {
    flex-direction: column;
  }

  .sideBySide {
    width: 100%;
  }

  .margin {
    width: 100%;
  }
}
