.itemContainer {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: solid 0.5px rgb(233, 233, 233);
    border-radius: 4px;
  }
  
  .column {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-basis: 0;
  }
  
  .columnMiddle {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
  }
  
  .columnButton {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  


  .itemContainer:hover {
    cursor: pointer;
  }

  .text {
      font-size: normal;
      text-transform: capitalize;
  }
  
  @media (max-width: 399px) {
    .text {
        font-size: smaller;
    }

    .columnMiddle {
      display: none;
    }
  }
  