.container {
  padding-left: 10px;
}

.title {
  cursor: pointer;
  font-weight: bold;
  font-size: medium;
  width: 120px;
}

.row {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 599px) {
  .title {
    font-size: small;
  }
}
