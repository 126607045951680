.footer {
  font-family: Inter;
  color: rgb(80, 80, 80);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 100px;
  padding-bottom: 20px;
  background: transparent;
  transition-timing-function: ease-in;
  transition: 0.25s;
}

.margin {
  padding-left: 190px;
  transition-timing-function: ease-out;
  transition: 0.25s;
}

.footer2 {
  background-color: #3e8cab;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  width: 95%;
  margin: auto;
}

.footer2Row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 0px;
  width: 100%;
}

.footer2links {
  width: 20%;
  padding-left: 2px;
  padding-right: 2px;
  color: white;
  font-size: 15px;
  text-align: center;
}

.footer2links:hover {
  cursor: pointer;
  color: rgb(8, 43, 48);
}

.footer2links:active {
  color: #ccc;
}

.footer2company {
  padding-top: 4px;
  font-size: 11px;
  color: black;
  display: flex;
  flex-direction: column;
  width: 160px;
  align-items: center;
  background-color: white;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding-bottom: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.seperator {
  font-size: 11px;
  color: gray;
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 599px) {
  .footer2links {
    padding-bottom: 15px;
    width: 50%;
    font-size: 10px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.row2 {
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 19px;
}

.iconsImage {
  cursor: pointer;
  height: 50px;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
}

.iconsImage2 {
  cursor: pointer;
  height: 20px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
