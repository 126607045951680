.container {
  height: 240px;
  width: 256px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  min-width: 175px;
  border-color: #ddecf2;
  background-color: white;
  flex-direction: row;
  border-radius: 10px;

  /** add shadow **/
  transition: 0.2s;
  box-shadow: 0 1px 3px gray;
  box-sizing: border-box;
  overflow: hidden;
}

.container:hover {
  cursor: pointer;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
}

.container:active {
  cursor: pointer;
  opacity: 0.5;
}

.imageContainer {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 60%;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.avatarContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 5px;
  height: 100%;
  position: relative;
}

.infoContainer {
  padding-left: 7px;
  padding-right: 7px;
}

.titleContainer {
  padding-top: 5px;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 42px;
}

.infoRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3px;
}

.title {
  min-height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: black;
  padding-left: 5px;
}

.details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 5px;

  font-size: medium;
  font-weight: bold;
  font-family: "Open Sans";
  color: rgb(52, 52, 52);
}

.ceContainer {
  width: 55px;
  height: 55px;
  z-index: 100;
  margin: 5px;
  opacity: 0.7;
  border: 1px solid #ccc;
  border-radius: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.detailsLight {
  font-size: 14px;
  font-weight: bold;
  font-family: "Open Sans";
  color: rgb(69, 69, 69);
}

.tooltipTitle {
  font-size: small;
  padding-bottom: 10px;
  padding-top: 3px;
  font-weight: bold;
  color: black;
}

.tooltipParagraph {
  color: black;
}

@media (max-width: 599px) {
  .container {
    height: 165px;
    width: 165px;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-right: 20px;
  }

  .imageContainer {
    width: 100%;
    height: 60%;
  }

  .avatarContainer {
    height: 100%;
    position: relative;
  }

  .infoContainer {
    padding-left: 5px;
    padding-right: 5px;
  }

  .infoRowContainer {
    padding-top: 1px;
  }

  .titleContainer {
    padding-top: 2px;
    min-height: 20px;
  }

  .title {
    min-height: 30px;
    font-size: x-small;
    padding-left: 2px;
  }

  .details {
    padding-left: 2px;
    font-size: x-small;
  }

  .ceContainer {
    width: 35px;
    height: 35px;
    z-index: 100;
    margin: 5px;
    opacity: 0.6;
    border-radius: 50px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }

  .detailsLight {
    font-size: x-small;
  }
}

/*Small device size search list only **/
.containerSearch {
  border-color: #ddecf2;
  background-color: white;
  border-radius: 5px;

  /** add shadow **/
  transition: 0.2s;
  box-shadow: 0 1px 3px gray;
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  height: 80px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 3px;
  margin-left: 3px;
}

.containerSearch:hover {
  cursor: pointer;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
}

.containerSearch:active {
  cursor: pointer;
  opacity: 0.5;
}

.imageContainerSearch {
  background-size: contain;
  width: 100%;
  height: 100%;
  flex-direction: row;
  border-top-right-radius: 0px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  background-repeat: no-repeat;
}

.infoContainerSearch {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 5px;
}

/*end Small device size search list only **/

.detailsIconButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.tooltipContainer {
  max-width: 200px;
}
