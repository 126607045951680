.locationInputSearch {
  color: red;
}

.titleButtonBar {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #e8e8e8;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.locationInputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  align-items: center;
  justify-content: flex-start;
  border-color: #ccc;
  padding: 10px;
}

.locationInput {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 12px;
  border: none;
  outline: none;
}

@media (min-width: 499px) {
  .locationInput {
    font-size: 15px;
  }
}

.icon {
  color: rgb(80, 79, 79);
}

.clearIcon {
  font-size: 5px;
}
