.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.dateRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
  border-top: solid 1px #ccc;
  border-bottom: 0px;
  border-radius: 10px 10px 0px 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.listContainer {
  padding: 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid 1px #ccc;
  border-radius: 10px 10px 10px 10px;
  padding-bottom: 10px;
  width: 100%;
}

.listItem:hover {
  cursor: pointer;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  color: black;
  padding-left: 10px;
}

.listItemColumn {
  margin-left: 0px;
  text-align: left;
  padding-right: 15px;
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.dateTitle {
  width: "100%";
  padding-top: 15px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-family: "Inter";
}

.listItemDelete {
  display: flex;
  padding: 0px;
}

.inputElementError {
  border-color: red;
}

.error {
  color: red;
}

.addButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 20px;
}

.dateExample {
  display: flex;
  margin-left: 30%;
  width: 100%;
  flex-direction: column;
  padding-bottom: 5px;
  font-style: italic;
}

.dateExampleDesc {
  width: 160px;
  text-align: left;
  flex-grow: 0;
}

.centeRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgb(33, 24, 124);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (min-width: 499px) {
  .dateRow {
    margin: auto;
    width: 60%;
  }

  .listContainer {
    margin: auto;
    width: 60%;
  }

  .listItemColumn {
    font-size: 14px;
  }
}

.itinerary {
  width: 100%;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 4px;
  font-family: "Inter";
  color: rgb(34, 78, 110);
  text-transform: uppercase;
  background-color: rgb(243, 243, 243);
}
