.buttonContainer {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
}

.disclaimer {
    font-size: small;
    margin-bottom: 10px;
    margin-left: 4px;
}