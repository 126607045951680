.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 40px;
  padding-bottom: 40px;
}

.phoneBorder {
  width: 400px;
  border: solid 1px rgb(137, 134, 134);
  border-radius: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.phoneHeader {
  background: #ccc;
  flex: 10%;
  font-weight: bold;
  font-size: 17px;
}

.headerTimeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.headerTimeRow2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(187, 181, 181);
}

.phoneContent {
  background: white;
  flex: 80%;
}

.phoneContentRow {
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.phoneContentInnerRow {
  display: flex;
  padding-bottom: 7px;
}

.phoneContentCenterRow {
  padding: 30px;
  text-align: center;
}

.phoneContentMainTitle {
  font-size: 18px;
  font-weight: bold;
}

.phoneContentTitle100 {
  font-size: 16px;
  padding-bottom: 5px;
  font-weight: bold;
  width: 100%;
}

.phoneContentTitle {
  font-size: 16px;
  font-weight: bold;
  width: 150px;
  min-width: 150px;
  padding-bottom: 5px;
  display: flex;
}

.phoneContentDescription {
  font-size: 14px;
  width: 70%;
  padding-bottom: 5px;
  white-space: pre-line;
}

.phoneContentText {
  font-size: 15px;
  white-space: pre-line;
}

.registrationOptionGroup {
  padding-top: 10;
  padding-bottom: 10px;
  font-size: 14px;
}

.registrationOptionTitle {
  padding-top: 10px;
  font-weight: bold;
}

.credits {
  font-weight: bold;
}

.price {
  font-weight: bold;
  color: green;
  /*rgb(199, 53, 53);*/
}

.priceStrike {
  font-weight: bold;
  color: rgb(199, 53, 53);
  text-decoration: line-through;
}

.phoneFooter {
  background: #ccc;
  flex: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  padding-left: 40px;

  padding-right: 40px;
  padding-bottom: 40px;
  border-top: 1px solid rgb(187, 181, 181);
}

.addToCart {
  display: flex;
  flex-direction: row;
  background-color: #527d8c;
  font-weight: bold;
  width: 100%;
  color: white;
  height: 55px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.helpText {
  font-size: 12px;
  font-style: italic;
}

.centerRow {
  display: flex;
  align-items: center;
}

.dateTitle {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 5px;
}

.installmentIndicator {
  color: green;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addressLink {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}

.addressLink {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: rgb(0, 136, 255);
}

.addressLink:hover {
  color: rgb(8, 49, 77);
}

.addressLink:active {
  color: #ccc;
}