.root {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgb(239, 237, 237);
  color: rgb(239, 237, 237);
  background: rgb(239, 237, 237);
  position: fixed;
  top: 56px;
  z-index: 1;
}

.tab {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  border: 0px;
  width: 100%;
  min-height: 70px;
  background-color: rgb(239, 237, 237);
}

.headerTitle {
  min-height: 75px;
  font-weight: bold;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  display: flex;
  font-size: x-large;
  text-transform: uppercase;
  background-color: transparent;
  width: 100%;
  background-color: gray;
  background-image: linear-gradient(to right,
      #1e75b7,
      #337cc6,
      #4882d4,
      #5d88e2,
      #728eef,
      #8998f4,
      #9da2f9,
      #b0adfe,
      #c5c0fe,
      #d8d3fd,
      #eae7fc,
      #fbfbfb);
}

@media (max-width: 499px) {
  .headerTitle {
    font-size: larger;

    padding-left: 10px;
  }
}