.dialogContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clickable {
  cursor: pointer;
  border: 1px solid white;
}

.clickable:hover {
  border: 1px dashed black;
}

.brandingDesc {
  font-size: medium;
  padding-bottom: 20px;
}

.brandingDescSize {
  font-size: medium;
  padding-bottom: 30px;
  font-style: italic;
}
