.registrationOptionGroup {
  padding-top: 10;
  padding-bottom: 10px;
  font-size: 14px;
}

.registrationOptionTitle {
  padding-top: 10px;
  font-weight: bold;
}

.registrationOptionTitle {
  padding-top: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.credits {
  font-weight: normal;
  padding-bottom: 10px;
}

.price {
  font-weight: bold;
  color: black;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid gray;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.checkboxOptionContainer {
  padding-top: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


@media (max-width: 599px) {
  .registrationOptionGroup {
    width: 60%;
    font-size: small;
  }

  .registrationOptionTitle {
    font-size: small;
    flex-direction: column;

    align-items: flex-start;
  }
}