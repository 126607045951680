.itemContainer {
  width: 100%;
  height: 85px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: 15px;
  border: solid 0.5px rgb(233, 233, 233);
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nameText {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  font-size: medium;
}

.otherText {
  font-size: small;
  color: rgb(62, 62, 62);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: green;
  width: 90px;
  height: 100%;
  color: white;
  cursor: pointer;
}

.button:active {
  opacity: 0.7;
}

@media (max-width: 399px) {
  .nameText {
    font-size: small;
  }

  .otherText {
    font-size: smaller;
  }
}
