.studentRow {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px dashed #ccc;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.amount {
  color: red;
}

.last {
  font-weight: bold;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.studentColumn {
  width: 20%;
}

.priceColumn {
  width: 10%;
}

.discountColumn {
  width: 10%;
}

.paidColumn {
  width: 15%;
}

.refundColumn {
  width: 10%;
}

.unregColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  font-style: italic;
  justify-content: center;
}

.refundEnterAmount {
  width: 20%;
}

.unregisteredStatus {
  color: rgb(244, 26, 102);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowTitle {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  padding-left: 5px;
  background-color: rgb(48, 47, 47);
  border-radius: 4px;
  color: white;
}
