.zubu {
  width: 310px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.zubuEditable {
  width: 310px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.zubuEditable:hover {
  outline-offset: -2px;
  outline: 2px dashed black;
  cursor: pointer;
  opacity: 0.3;
}

.bodyContainer {
  margin: 1px;
  display: flex;
  justify-content: center;
}

.bodySubContainer {
  border: 1px solid black;
}

.bodySubSubContainer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  background: white;
}

.pageContainer {
  background-color: white;
  padding: 0px 25px 25px 25px;
}

.title {
  font-size: 14px;
  text-align: center;
  padding: 10px;
  font-family: "IM Fell French Canon SC", serif;
  color: black;
  font-weight: bold;
  padding-bottom: 0px;
}

.subText {
  font-size: 9px;
  text-align: center;
  padding: 0px;
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
}

.name {
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
  font-family: "Great Vibes";
  color: black;
}

.line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: 8px;
  color: black;
}

.signatureContainer {
  width: 100%;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

.signatureWrap {
  width: "100%";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signatureTitle {
  display: block;
  padding-left: 10px;
  font-size: 9px;
  font-style: italic;
  color: black;
  text-align: center;
}

.image {
  height: 40px;
}