.container {
  margin-top: 10px;
}

.carouselContainer {
  border: 0px;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 15px;
}

.label {
  font-weight: bold;
  display: block;
  margin-bottom: 14px;
  color: rgb(131, 131, 131);
}

.sliderImage {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
}

.thumbnailContainer {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
}

.thumbnail {
  height: 51px;
  border-radius: 0px;
  box-sizing: border-box;
}

.thumbnail:hover {
  border: 0.5px dashed rgb(109, 109, 109);
}

.thumbnailButton {
  outline: none;
  margin: 0px;
  padding: 0px;
  border: none;
  background: none;
}

.slideButton {
  outline: none;
  margin: 0px;
  padding: 0px;
  border: none;
  background: none;
  color: rgb(100, 100, 100);
}

.slideButton:hover {
  color: rgb(31, 132, 195);
}

.sliderButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 15px;
}

.headlineContainer {
  padding-top: 20px;
}

.switchInputContainer {
  padding-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.row {
  display: flex;
}
