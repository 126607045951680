.sectionTitle {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;

  align-items: center;
  justify-content: space-between;
  line-height: 40px;
  margin: auto;
  border-radius: 5px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 60%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}

.form {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: auto;
}

.label {
  font-weight: bold;
  display: block;
  margin-bottom: 14px;
  color: rgb(131, 131, 131);
}

.input {
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 16px;
}

.matInput {
  width: 100%;
  margin-right: 40px;
  justify-content: center;
}

.pricingContainer {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 400px) {
  .formLogo {
    width: 100px;
  }
}

@media (min-width: 499px) {
  .input {
    font-size: 14px;
    width: 60%;
    margin: auto;
  }

  .matInputContainer {
    width: 70%;
  }
}

@media (min-width: 499px) {
  .pricingContainer {
    font-size: 14px;
    width: 60%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex: 50%;
    flex-direction: row;
    justify-content: flex-end;
  }

  .matInput {
    font-size: 14px;
    width: 70%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.emptyScreen {
  height: 60vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 500px) {
  .sectionTitle {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .buttonContainer {
    width: 100%;
  }
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
