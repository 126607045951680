.summaryContainer {
  border: 1px solid #ccc;
  padding-left: 20px;
  padding-right: 15px;

  padding-top: 14px;
  padding-bottom: 15px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 60px;
  min-height: 100px;
}

.emptyMethodsContainer {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selectButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}