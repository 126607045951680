.phoneBadeContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.appleBadge {
  width: 140px;
}

.androidBadge {
  width: 153px;
}

.appleBadgeSmall {
  width: 100px;
}

.androidBadgeSmall {
  width: 113px;
}
