.dialogTitle {
  background-color: rgb(231, 231, 231);
}

.blurbLogoContainer {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.dialogContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.dialogLogoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logoWrapper {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgb(127, 127, 127);
  box-sizing: border-box;
}

.blurbWrapper {
  font-size: small;
  padding-top: 10px;
  color: black;
  max-width: 400px;
}

.blurbAndBubbleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 699px) {
  .blurbAndBubbleContainer {
    flex-direction: column;
    align-items: center;
  }
}
