p {
  font-style: normal;
  padding: 0;
}

.link {
  font-size: 13px;
}

.listItem {
  font-size: 13px;
}

strong {
  font-size: 14px;
}

.title {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}

.screen {
  padding-top: 40px;
  margin-bottom: 60px;
  margin-left: 30px;
  margin-right: 40px;
}

.address {
  margin-left: 25px;
  padding: 10px;
}
