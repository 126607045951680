.screen {
  width: 100%;
}

.promoContainer {
  padding-top: 15px;
  background: rgb(199, 214, 221);
  background: linear-gradient(
    349deg,
    rgba(199, 214, 221, 1) 0%,
    rgba(255, 255, 255, 1) 0%,
    rgba(247, 254, 255, 1) 12%,
    rgba(235, 246, 248, 1) 100%
  );
}

.promo {
  margin: auto;
  text-align: center;
  width: 97%;
  background-color: #439aa2;
  border-radius: 10px;
  overflow: hidden;
  border: 0px;
  transition: 0.3s;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid white;
}

.promoImage {
  padding: 0px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/providerSlider/summer_sale.png");
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 530px;
}

.promoFooter {
  background-color: #439aa2;
  color: white;
  width: 100%;
  padding: 20px;
  padding-bottom: 15px;
  font-family: "open-sans";
}

.promoTerms {
  text-align: justify;
  font-size: smaller;
  padding: 20px;
  color: white;
}

.background {
  background-image: url("../../assets/images/welcome/back3.png");
  background-repeat: no-repeat;
  margin-top: 85px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pageContainerLight {
  background: rgb(199, 214, 221);
  background: linear-gradient(
    349deg,
    rgba(199, 214, 221, 1) 0%,
    rgba(255, 255, 255, 1) 0%,
    rgba(247, 254, 255, 1) 12%,
    rgba(235, 246, 248, 1) 100%
  );
  padding-top: 60px;
  padding-bottom: 80px;
}

.centerTitleContainer {
  display: flex;
  flex-direction: row;
}

.floatingTitle4 {
  padding-bottom: 60px;
  font-family: Inter;
  font-size: 37px;
  /*text-transform: uppercase;*/
  color: rgb(19, 19, 19);
  text-align: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.blubsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.blurbFaqContainer {
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
  width: 23%;
  margin-left: 5px;
  margin-right: 5px;
  min-height: 265px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  transition: 0.3s;
  padding: 0px;
}

.blurbPriceContainer {
  background-color: gray;
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
  width: 23%;
  margin-left: 5px;
  margin-right: 5px;
  min-height: 210px;

  flex-direction: column;
  align-items: center;
  align-content: center;
  transition: 0.3s;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  border: 0px solid transparent;
}

.blurbFaqTitle {
  padding-top: 15px;
  padding-bottom: 12px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.blurbPriceTitle {
  padding-top: 15px;
  padding-bottom: 12px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  width: 100%;
}

.blurbPriceDescription {
  color: rgb(43, 43, 43);
  font-size: 16px;
  padding: 20px;
}

.spacer {
  height: 100px;
}

.rightImageContainer {
  width: 40%;
}

@media (max-width: 1200px) {
  .blurbFaqContainer {
    width: 40%;
    margin-bottom: 40px;
    display: flex;
  }

  .blurbPriceContainer {
    width: 40%;
    margin-bottom: 40px;
    display: flex;
  }

  .spacer {
    height: 30px;
  }

  .promoImage {
    height: 430px;
  }
}

@media (max-width: 599px) {
  .rightImageContainer {
    display: none;
  }

  .blurbFaqContainer {
    width: 100%;
    margin-bottom: 40px;
    min-height: 100px;
  }

  .blurbPriceContainer {
    width: 100%;
    margin-bottom: 40px;
    min-height: 100px;
  }

  .spacer {
    height: 0px;
  }

  .pageContainerLight {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .promoImage {
    border-radius: 0px;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 570px;
    background-image: url("../../assets/images/providerSlider/summer_sale_vertical.png");
  }

  .promo {
    margin-left: 5px;
    margin-right: 5px;
    width: 95%;
  }
}
