.screen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: white;
}

.cards {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.list {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.item1-enter {
  opacity: 0;
}

.item1-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  animation-fill-mode: forwards;
}

.item1-exit {
  opacity: 1;
}

.item1-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
  animation-fill-mode: forwards;
}

.searchContainer {
  display: flex;
  flex: row;
  align-items: center;
  margin-top: 155px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.searchTextField {
  flex: 3;
  padding-left: 30px;
  width: 50%;
}

.searchRevenue {
  flex: 1;
  margin-right: 20px;
}

.searchActionButtons {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  justify-content: flex-start;
  height: 57px;
}

.badge {
  background-color: green;
  color: white;
}