.container {
  border: 1px solid gray;
  width: 90%;
  height: 400px;
  padding: 10px;
  font-size: 13px;
  color: gray;
  border-radius: 3px;
  overflow: scroll;
  text-align: justify;
}
