.container {
  cursor: pointer;
  width: 50px;
}

.container:hover {
  opacity: 0.7;
}

.langContainer {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 500px) {
  .container {
    width: 30px;
  }
}