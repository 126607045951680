.fullContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
}

.container {
  display: flex;
  flex-direction: row;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px; /* 5px rounded corners */
  padding: 0px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cardContainer {
  color: rgb(21, 121, 148);
}

.balance {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  text-transform: uppercase;
  width: 160px;
  padding-right: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  display: flex;
  padding-bottom: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bankAccountContainer {
  text-transform: uppercase;
  width: 100%;
}

.bankAccountRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
}

.bankContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-height: 40px;
}

.list {
  display: flex;
  flex-direction: row;
  min-width: 241px;
}

.equation {
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  /*background-color: rgb(238, 238, 238);*/
  margin: 5px;
  width: 0px;
  height: 20px;
  border-radius: 50%;
}

.simpleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
