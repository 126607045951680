.fullscreen {
  height: 100%;
}

.content {
  height: 100%;
  margin-top: 56px;
  background-color: white;
  min-height: 440px;
  transition-timing-function: ease-in;
  transition: 0.25s;
}

.contentWithDockedMenu {
  height: 100%;
  margin-top: 56px;
  background-color: white;
  min-height: 440px;
  padding-left: 197px;
  transition-timing-function: ease-out;
  transition: 0.25s;
}
