title {
    display: flex;
    flex-direction: row;
}

.lottieImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px;
    padding: 0px;
    margin: 0px;
}

.center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 40px;
}

.image {
    width: 170px;
    height: 170px;
}

.helpContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    font-size: small;
}

.imageHelp {
    width: 160px;
}