* {
  box-sizing: border-box;
}

.divp {
  padding-bottom: 10px;
  font-size: smaller;
}

.form {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 2%;
  overflow: hidden;
}

.sectionTitle {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;

  align-items: center;
  justify-content: space-between;
  line-height: 40px;
  margin: auto;
  border-radius: 5px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 60%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonContainer {
  padding-top: 0px;
  width: 60%;
  margin: auto;
  display: flex;
  justify-items: flex-end;
  justify-content: flex-end;
  justify-self: flex-end;
}

.pricingContainer {
  width: 60%;
  padding-top: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;

  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 500px) {
  .sectionTitle {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .buttonContainer {
    width: 100%;
  }

  .pricingContainer {
    font-size: 12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: 50%;
    padding-right: 10px;
    flex-direction: row;
    padding-right: 5px;
    justify-content: space-between;
  }
}
