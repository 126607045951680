.summaryContainer {
    font-weight: bold;
    font-size: medium;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    align-items: center;
    font-size: small;
    color: rgb(46, 67, 106);
    background-color: #b8cff9;
    height: 50px;

}

.tabStyle {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.iconButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    /* #b8cff9; */
    height: 65px;
}

/* ----- Form ----- */

.sectionTitle {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: space-between;
    line-height: 40px;
    margin: auto;
    border-radius: 5px;
    color: white;
    font-family: Inter;
    text-transform: uppercase;
    width: 60%;
    font-size: 16px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 35px;
    text-align: center;
    background: rgb(2, 0, 36);
    background: linear-gradient(15deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(136, 174, 190, 1) 55%,
            rgba(247, 254, 255, 1) 100%);
}

.centerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .sectionTitle {
        width: 100%;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .formElementContainer {
        width: 100%;
    }
}

.confirmModalContainer {
    padding: 10px;
}

.confirmModalDisclaimer {
    font-style: normal;
    font-weight: bold;
    color: black;
    padding-bottom: 20px;
}

.confirmModalInfoContainer {
    font-style: normal;
    color: black;
    padding-bottom: 20px;
}

.row {
    display: flex;
    flex-direction: row;
}

.bold {
    font-weight: bold;
}

.empty {
    background-color: yellow;
    width: 200px;
    border-radius: 5px;
}

.barButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


.pricingContainer {
    width: 100%;
    padding-bottom: 40px;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    flex: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    text-align: justify;
}

@media (min-width: 499px) {
    .pricingContainer {
        font-size: 14px;
        width: 60%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        flex: 50%;
        padding-right: 10px;
        flex-direction: row;
        padding-right: 5px;
        justify-content: space-between;
    }
}

/* -----End  Form ----- */



@media (max-width: 599px) {}