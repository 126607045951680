.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

input[type="file"] {
  height: 0.1px;
  width: 0.1px;
  color: white;
  background: white;
  background-color: white;
  position: absolute;
  top: -100vh;
  display: block;
  overflow: hidden;
  display: none;
}

.label {
  color: white;
  height: 60px;
  width: 250px;
  background-color: "red";
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.file {
  width: 170px;
  display: flex;
}

.fileSignature {
  width: 340px;
}

.headerFooter {
  width: 312px;
}

/*disabled image*/
.imageSignaturePlain {
  width: 340px;
  height: 85px;
}

.avatar {
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(146, 146, 146);
  box-sizing: content-box;
  overflow: hidden;
  border: 1px solid #ccc;
  cursor: default;
}

.avatarImage {
  width: 60px;
  height: 60px;
}

/*disabled image*/
.imagePlain {
  width: 170px;
  height: 170px;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgb(146, 146, 146);
  cursor: pointer;
  box-sizing: content-box;
  overflow: hidden;
  border: 1px solid #ccc;
  cursor: default;
}

.imageBox {
  width: 170px;
  height: 170px;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: gray;
  cursor: pointer;
  box-sizing: content-box;
  overflow: hidden;
}

.imageSignatureBox {
  width: 340px;
  height: 85px;
}

.imageBox:hover {
  border: 1px solid black;
}

.disableImage:hover {
  /* border: 1px solid #ccc;*/
  cursor: default;
}

.imageBoxContainer {
  margin: auto;
  width: 60%;
  margin-bottom: 30px;
}

@media screen and (max-width: 500px) {
  .imageBoxContainer {
    width: 100%;
  }
}

@media screen and (max-width: 599px) {
  .avatar {
    width: 30px;
    height: 30px;
  }

  .avatarImage {
    width: 30px;
    height: 30px;
  }
}

.header {
  color: gray;
  font-size: 12px;
  padding-bottom: 3px;
}

/* Header footer certificate */

.imageHeaderFooterBox {
  border-radius: 0px;
}

.imageHeaderFooterBox:hover {
  outline-offset: -2px;
  outline: 2px dashed black;
  cursor: pointer;
  opacity: 0.3;
}

.zubuEditable {
  width: 312px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.removePicture {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-size: smaller;
  font-style: italic;
  position: relative;
  top: -15px;
  height: 0px;
}

.fileProviderCoverPhoto {
  width: 512px;
}

.imageProviderCoverPhotoBox {
  width: 512px;
  height: 127px;
}

.providerCoverPhotoImageSize {
  width: 512px;
}
