.logo {
  background-color: white;
  padding: 12px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 35px;
}

.logo img {
  height: 100%;
}

.logozubu {
  height: 85px;
  cursor: pointer;
}

.logozubufixed {
  height: 45px;
  cursor: pointer;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (max-width: 499px) {
  .logozubu {
    height: 35px;
  }
}


@media (max-width: 599px) {
  .logozubu {
    height: 45px;
  }
}
