.screen {
  width: 100%;
  margin-top: 85px;
  align-items: center;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.courseContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  max-width: 1500px;
  margin: auto;
}

.banner {
  width: 33%;
}

.listingContainer {
  min-height: 50vh;
}

.floatingTitle4 {
  padding-bottom: 60px;
  font-family: Inter;
  font-size: 34px;
  color: rgb(19, 19, 19);
  text-align: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.buttonContainer {
  padding-top: 30px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.titleBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.headerButton {
  color: gray;
  font-size: 21px;
  font-family: "Inter";
}

.headerIcon {
  visibility: visible;
}

.aboutContainer {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  font-size: small;
}

.aboutTitle {
  padding-top: 5px;
  font-weight: bold;
}

.about {
  white-space: pre-wrap;
}

.courseViewerContainer {
  width: 60%;
  margin: auto;
}

.gap {
  height: 85px;
}

.rightImageContainer2 {
  text-align: center;
}

.floatingContainer {
  position: fixed;
  right: 0px;
}

.princeRange {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  font-family: Inter;
  font-size: xx-large;
  color: green;
}

.courseDetails {
  padding-top: 0px;
  font-weight: bold;
  font-size: small;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.listContainer {
  display: flex;
  align-items: flex-start;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.question {
  font-size: 15px;
  padding-bottom: 13px;
}

.bubble {
  border-radius: 10px;
  padding: 10px;
  width: 250px;
  border: 0.5px solid #ccc;
  margin: 10px;
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.storefrontImage {
  width: 99%;
  max-width: 1500px;
  border-radius: 10px;
  margin-top: 10px;
}

.providerDescContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
  max-width: 1400px;
  margin: auto;
}

@media (max-width: 400px) {
  .headerButton {
    font-size: 16px;
    width: 10px;
  }

  .headerIcon {
    width: 0px;
    visibility: hidden;
  }
}

@media (max-width: 499px) {
  .gap {
    height: 55px;
  }

  .bigToothImage {
    max-width: 100%;
    height: auto;
  }

  .providerDescContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 599px) {
  .screen {
    margin-top: 55px;
  }

  .courseViewerContainer {
    width: 100%;
  }

  .floatingContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .aboutContainer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .aboutTitle {
    font-size: x-small;
  }

  .about {
    font-size: x-small;
  }
}