.container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 10px;
}

.avatarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
}

.summaryContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-top: 5px;
  min-height: 90px;
  background-color: white;
  transition: 0.3s;
  box-shadow: 0 2px 3px #ccc;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
  width: 60%;
  border-radius: 10px;
}

.summaryContainer:hover {
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
}

.headerTextContainer {
  padding: 5px;
  padding-bottom: 0px;
  align-items: center;
}

.subTitle {
  font-size: large;
}

.textCE {
  color: #3e8cab;
  font-size: x-large;
}

.creditsCalendar {
  font-weight: bold;
  font-size: large;
}

@media (max-width: 799px) {
  .summaryContainer {
    width: 100%;
  }
}

@media (max-width: 499px) {
  .container {
    padding: 0px;
    margin-top: 15px;
  }

  .summaryContainer {
    min-height: 75px;
  }

  .subTitle {
    font-size: small;
  }

  .textCE {
    font-size: medium;
  }

  .creditsCalendar {
    font-size: small;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 0px;
    margin-top: 10px;
  }
}