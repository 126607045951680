.container {
    padding-bottom: 30px;
}
  
.itemContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:20px;
}
  
.itemContainer:hover {
    background-color: #ccc;
    border-radius: 15px;
}
  
.removeButton {
    font-weight: bold;
    display:flex;
    flex-direction: row;
    align-items: center;
}

.removeButton:hover {
    color: rgb(31, 97, 190);
    cursor: pointer;
}

.row {
    display:flex;
    flex-direction: row;
    align-items: center;
}

