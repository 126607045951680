.itemContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 0.5px rgb(233, 233, 233);
  border-radius: 4px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
}

.column {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.readMore {
  font-size: 14px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/**/
.column1 {
  padding-right: 15px;
  min-width: 250px;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desc {
  width: 90px;
}

.column2 {
  padding-left: 5px;
  padding-right: 15px;
  width: 50%;
}

.column3 {
  padding-right: 15px;
  flex-flow: column nowrap;
  width: 30%;
}

.titleSide {
  font-weight: bold;
  padding-right: 10px;
  /**color: green;**/
}

.titleTop {
  font-weight: bold;
  width: 100%;
  text-align: left;
  padding-bottom: 2px;

  display: flex;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .column1 {
    width: 100%;
  }
  .column2 {
    display: none;
  }
  .column3 {
    display: none;
  }
}

.promoContainer {
  width: 300px;
}

.sentText {
  color: rgb(203, 152, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}

.resendText {
  color: blue;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hover {
  color: blue;
  font-size: 13px;
  cursor: pointer;
}
