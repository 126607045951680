.invisibleContainer {
  display: flex;
  flex-direction: column;
  width: 425px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  cursor: pointer;
}

.imageInfoContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;

  border-radius: 10px;
  transition: 0.3s;
  box-shadow: 0 2px 3px #ccc;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 5px;
}

.imageInfoContainer:hover {
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
}

.imageContainer {
  width: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: center;
}

.infoContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 10px;
  padding-top: 4px;
  min-height: 114px;
}

.buttonRow {
  display: flex;
  flex-direction: column;
}

.bottomRow {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 7px;
}

/*************************************/

.title {
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.date {
  display: flex;
  flex-direction: column;
  min-height: 39px;
}

.headerTitleText {
  color: green;
  font-weight: bold;
  font-family: "inter";
}

@media (max-width: 499px) {
  .infoContainer {
    font-size: small;
  }

  .headerTitleText {
    font-size: small;
  }

  .title {
    font-size: small;
  }

  .imageContainer {
    background-size: cover;
  }
}

@media (max-width: 400px) {
  .infoContainer {
    min-height: 100px;
  }

  .invisibleContainer {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
  }

  .container {
    width: 100%;
  }

  .imageContainer {
    background-size: cover;
    flex-direction: row;
    align-items: flex-start;
  }
}
