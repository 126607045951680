.container {
  border: 1px solid #ccc;
  padding-left: 20px;
  padding-right: 15px;
  padding-top: 14px;
  padding-bottom: 15px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.rowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textField {
  width: 65%;
}
