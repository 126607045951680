.videoChapterItemListContainer {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
}

.videoChapterItemContainer {
    display: flex;
    flex-direction: row;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 15px;
    align-items: center;
}

.videoChapterItemContainerNew {
    display: flex;
    flex-direction: row;
    height: 100px;
    border: 2px dashed #ccc;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;
}

.videoChapterItemIndex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 10px;
}

.videoChapterItemPreview {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.videoChapterItemDescContainer {
    height: 100%;
    width: 100%;
    padding-top: 3px;
}

.videoChapterItemTitle {
    font-weight: bold;
    font-size: small;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.videoChapterItemDesc {
    font-size: smaller;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.videoChapterItemActionContainer {
    display: flex;
}

.videoSummary {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 15px;
    color: rgb(73, 76, 80);
    font-weight: bold;
}

@media (min-width: 499px) {
    .videoChapterItemListContainer {
        width: 60%;
        margin: auto;
        padding-right: 10px;
        padding-right: 5px;
    }
}

.videoChapterItemContainer:hover {
    cursor: pointer;
}

.videoChapterItemContainerNew:hover {
    border: 2px dashed rgb(62, 128, 227);
    cursor: pointer;
}

.simpleRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* courseContentChapter */

.courseContentChapter {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 0px;
    transition: 0.3s;
    box-shadow: 0 0px 0px gray;
    box-sizing: border-box;
    overflow: hidden;
}

.courseContentChapter:hover {
    cursor: pointer;
    box-shadow: 0 8px 18px 0 #bcc9d5;
    border: 1px solid #bcc9d5;
}

.courseContentTitleDesc {
    padding-left: 10px;
}

.courseContentTitle {
    display: flex;
    flex-direction: row;
    font-size: medium;
}

.courseContentDescription {
    display: flex;
    flex-direction: row;
    font-size: small;
    padding-top: 10px;
    font-weight: normal;
}

.courseContentDuration {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: medium;
    padding-top: 10px;
    justify-content: flex-end;
}

/* courseContentChapter */