.title {
  font-size: 17px;
  padding-bottom: 30px;
  text-align: center;
  font-weight: bold;
}

.description {
  font-size: 16px;
  padding-bottom: 20px;
}

.summaryContainer {
  border: 1px solid #ccc;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 30px;
  min-height: 100px;
}

.summaryTextItalic {
  font-size: 15px;
  font-style: italic;
}

.summaryText {
  font-size: 15px;
}

.summaryPriceText {
  font-weight: bold;
  font-size: 15px;
}

.summaryRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.summaryRowTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
}

.priceRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 700px) {
  .formLogo {
    height: 300px;
  }
  .title {
    font-size: 17px;
  }

  .description {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .formLogo {
    display: none;
  }
  .title {
    font-size: 16px;
  }

  .description {
    font-size: 13px;
  }
}

.descColumn {
  width: 60%;
  padding: 0px;
}

.deleteContainer {
  width: 10%;
}

.menuButton {
  color: red;
}

.selectButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
}

.emptyScreen {
  height: 60vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.sectionTitle {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;

  align-items: center;
  justify-content: space-between;
  line-height: 40px;
  margin: auto;
  border-radius: 5px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 60%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}

.container {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 15px;
}

.cencontainer {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 15px;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 500px) {
  .sectionTitle {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .container {
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 15px;
  }
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkoutButton {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.lockIcon {
  padding-bottom: 4px;
  color: green;
}

.receipt {
  color: black;
  padding-bottom: 45px;
}

.receiptTitle {
  display: flex;
  flex-direction: row;
}
