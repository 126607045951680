.storefrontContainer {
  display: flex;
  flex-direction: row;
}

.storefrontImage {
  width: 170px;
  height: 170px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.image {
  width: 120px;
  height: 120px;
}

.emptyLogo {
  display: flex;
  flex-direction: column;
  color: #3e8cab;
  padding: 0px;
  margin: 0px;
  font-size: 120px;
}

.container {
  height: 200px;
  width: 200px;
  margin-bottom: 10px;
  margin-right: 0px;
  margin-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid white;
}

.containerNoHeight {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.clickContainer {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
}

.clickContainer:hover {
  cursor: pointer;
  border: 1px solid rgb(42, 84, 180);
}

.clickContainer:active {
  cursor: pointer;
  opacity: 0.5;
}

.providerName {
  font-weight: bold;
  font-size: small;
  padding: 5px;
  text-align: center;
}

@media (max-width: 599px) {
  .providerName {
    padding-left: 0px;
  }

  .storefrontImage {
    width: 45px;
    height: 45px;
  }

  .image {
    width: 45px;
    height: 45px;
  }

  .emptyLogo {
    font-size: 46px;
  }

  .container {
    height: 100px;
    width: 100px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 3px;
    padding: 5px;
  }

  .clickContainer {
    height: 100px;
    width: 100px;
    margin-bottom: 5px;
    border-radius: 10px;
    overflow: hidden;
    padding: 5px;
  }

  .providerName {
    font-size: xx-small;
  }
}