.card {
  width: 160px;
  height: 45px;
  margin: 10px;
  transition: 0.3s;
  box-shadow: 0 2px 3px gray;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
}

.card:active {
  cursor: pointer;
  opacity: 0.5;
}

.center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: small;
  color: white;
  text-transform: uppercase;
}
