.container {
  display: flex;
  flex-direction: row;
  min-height: 90vh;
}

.pageContent {
  width: 80%;
  padding: 0px;
}
