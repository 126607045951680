.screenEmpty {
  height: 60vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.form {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 2%;
  overflow: hidden;
}

.summaryContainer {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  min-height: 100px;
}

.summaryTextItalic {
  font-size: 15px;
  font-style: italic;
}

.summaryText {
  font-size: 15px;
}

.summaryPriceText {
  font-weight: bold;
  font-size: 15px;
}

.summaryRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
}

.summaryRowTotal {
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
  /* border-top: 1px solid #ccc;*/
}

.description {
  font-size: 15px;
  padding-bottom: 20px;
}

@media screen and (max-width: 700px) {
  .description {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .description {
    font-size: 13px;
  }
}

.pricingContainer {
  width: 100%;
  margin: auto;
  padding-top: 0px;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  text-align: justify;
}

@media (min-width: 499px) {
  .pricingContainer {
    font-size: 14px;
    width: 60%;
    margin: auto;
    display: flex;
    flex: 50%;
    padding-right: 10px;
    flex-direction: row;
    padding-right: 5px;
    justify-content: space-between;
  }

  .summaryContainer {
    width: 60%;
    margin: auto;
  }

  .buttonContainer {
    width: 60%;
    margin: auto;
  }
}

.icon {
  padding-right: 15px;
}

.sectionTitle {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 5px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 60%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}

@media screen and (max-width: 500px) {
  .sectionTitle {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.buttonContainer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.helpBlurb {
  padding-top: 0px;
  padding-bottom: 5px;
}

.phoneContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
  align-items: center;
}

.phonePic {
  height: 240px;
}
