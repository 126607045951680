.card {
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  margin: 0px;
  min-width: 690px;
}
.sectionContainer {
  width: 100%;
  padding: 20px;
}

.sectionTitle {
  font-size: 14px;
  font-family: Inter;
  text-transform: uppercase;
  color: rgb(149, 149, 149);
}

.sectionContentContainer {
  padding-top: 10px;
}

.chargeContainer {
  font-size: 15px;
  margin-top: 10px;
}

.chargeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.amount {
  color: red;
}

.courseTitle {
  color: rgb(34, 102, 161);
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  text-decoration: underline;
  font-size: 14px;
}

.regOption {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}

.spaceBetweenLayout {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disabled {
  opacity: 0.4;
}
