.messageContainerLeft {
  display: flex;
  flex-direction: row;
  padding-bottom: 35px;
  padding-left: 10px;
}

.messageContainerRight {
  display: flex;
  flex-direction: row;
  padding-bottom: 35px;
  padding-right: 10px;
  justify-content: flex-end;
}

.leftBuble {
  padding: 6px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  background-color: rgb(223, 225, 225);
  transition: 0.3s;
  box-shadow: 0 2px 3px rgb(184, 184, 184);
  box-sizing: border-box;

  max-width: 350px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.rightBuble {
  padding: 6px;
  border-radius: 20px;
  border-bottom-right-radius: 0px;
  background-color: rgb(87, 155, 184);
  transition: 0.3s;
  box-shadow: 0 2px 3px rgb(184, 184, 184);
  box-sizing: border-box;

  max-width: 350px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.messageAvatarContainer {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
}

.messageContentContainer {
  padding-left: 10px;
}

.messageContentContainerLeftPanel {
  padding-left: 10px;
}

.messageUserNameDateContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.messageUserName {
  font-weight: bold;
  font-size: smaller;
  padding-right: 5px;
}

.rightMessageDate {
  font-size: x-small;
  color: rgb(255, 255, 255);
}

.leftMessageDate {
  font-size: x-small;
  color: gray;
}

.conversationMessageTextWrapper {
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  font-size: smaller;
  width: 180px;
}

.conversationMessageTextWrapperInEdit {
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  font-size: smaller;
  width: 160px;
}

.conversationMssageText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rightMessageText {
  padding-top: 2px;
  font-size: smaller;
  display: flex;
  flex-direction: row;
  color: white;
}

.leftMessageText {
  padding-top: 2px;
  font-size: smaller;
  display: flex;
  flex-direction: row;
}

.conversationContainerSelected {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0.5px solid rgb(225, 223, 223);
  border-radius: 5px;
  margin: 5px;

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  background-color: rgb(173, 230, 249);
}

.conversationContainer {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0.5px solid rgb(225, 223, 223);
  border-radius: 5px;
  margin: 5px;

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.conversationContainer:hover {
  background-color: rgb(222, 246, 254);
  cursor: pointer;
}

.conversationsTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-weight: bold;
}

.conversationList {
  background-color: rgba(247, 247, 247, 0.92);
  width: 30%;
  max-height: 500px;
  overflow-x: auto;
}

.conversationListFull {
  background-color: rgba(247, 247, 247, 0.92);
  width: 100%;
  min-width: 270px;
  max-height: 500px;
  overflow-x: auto;
}

.dotContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 7px;
}

.dot {
  background-color: rgb(255, 5, 5);
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

@media (max-width: 499px) {
  .messageContentContainerLeftPanel {
    display: none;
  }

  .conversationsTitle {
    display: none;
  }
}

.deleteCheck {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.deleteButton {
  padding: 0px;
  margin: 0px;
}
