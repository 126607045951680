.playerContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.player {
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 240px;
    width: 250px;
    background-color: black;
    margin-bottom: 10px;
}

.description {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.chapterInfo {
    flex: 1;
    margin-left: 30px;
}