.paper {
  background: white;
  border: 0;
  box-shadow: 0px 0px 15px gray;
  width: 80%;
}

.paperSmall {
  background: white;
  border: 0;
  box-shadow: 0px 0px 15px gray;
  width: 25%;
}

.paper {
  background: white;
  border: 0;
  box-shadow: 0px 0px 15px gray;
  width: 80%;
}

.paperFull {
  background: white;
  border: 0;
  box-shadow: 0px 0px 15px gray;
  width: 100%;
}

.drawer {
  cursor: pointer;
  color: #437aa1;
  padding-left: 14px;
  padding-top: 16px;
  padding-bottom: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.filterClearContainer {
  padding-top: 10px;
  padding-left: 10px;
  min-height: 50px;
}

.sortButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

.sortButton {
  width: 300px;
  margin-bottom: 10px;
}

.hr {
  border: 0.5px solid rgb(228, 227, 227);
}

@media (max-width: 599px) {
  .sortButton {
    width: 90%;
  }
}
