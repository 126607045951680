.itemContainer {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: solid 0.5px rgb(233, 233, 233);
    border-radius: 4px;
}

.title {
    max-width: 600px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.title2 {
    max-width: 600px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: medium;
}

.columnContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.rowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column1 {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

@media (max-width: 499px) {
    .title {
        max-width: 150px;
        font-size: small;
    }

    .title2 {
        max-width: 300px;
        font-size: x-small;
    }

    .column1 {
        padding-left: 5px;
        padding-right: 5px;
    }
}