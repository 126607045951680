.container {
  border: 1px solid #ccc;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 30px;
  width: 60%;
  margin: auto;
}

.price {
  font-weight: bold;
}

.priceTotal {
  font-size: 17px;
  padding-bottom: 10px;
  color: black;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 15px;
}

.orderNumberContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.orderHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.showDetailsContainer {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.link:active {
  color: #ccc;
}

.installmentContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 15px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

.installmentRow {
  padding-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.installmentInnerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .container {
    width: 100%;
  }
}