.loadMoreContainer {
    padding-top: 25px;  
 
}

.listContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ratingCardContainer {
   
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 499px) {
    .ratingCardContainer {
      width: 97%;     
    }
}
  