.screen {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
}

.formAndFooter {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 130px;
}

.formFlex {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  font-size: 14px;
}

.buttonContainer {
  padding-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  width: 90%;
  color: black;
  font-size: 20px;
  font-family: Inter;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 0px;
}

.reCaptcha {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 92%;
}

.sectionTitle {
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 40px;
  margin: auto;
  border-radius: 5px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 60%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}

.imageContainer {
  width: 1025px;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/inApp/sign_up_help_en.png");
}

.imageContainerFr {
  background-image: url("../../../assets/images/inApp/sign_up_help_fr.png");
}

.icon {
  padding-right: 15px;
}

.rowIt {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1025px) {
  .imageContainer {
    width: 768px;
    height: 225px;
  }
}

@media (max-width: 768px) {
  .imageContainer {
    width: 576px;
    height: 168px;
    margin-bottom: 20px;
  }
}

@media (max-width: 599px) {
  .imageContainer {
    width: 339px;
    height: 283px;
    background-image: url("../../../assets/images/inApp/sign_up_help_en_mobile.png");
  }

  .imageContainerFr {
    background-image: url("../../../assets/images/inApp/sign_up_help_fr_mobile.png");
  }

  .container {
    margin-top: 70px;
  }
}

@media (max-width: 499px) {
  .formFlex {
    width: 93%;
    font-size: 12px;
  }

  .sectionTitle {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .formAndFooter {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.spacerTen {
  height: 7px;
}

.spacerFive {
  height: 4px;
}
