.screen {
  width: 100%;
  margin-top: 15px;
}

.categoriesContainerTop {
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.categoryRootContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.categoriesContainer {
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  text-align: center;
}

.welcomeToZubUContainer {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-top: 85px;
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: linear-gradient(to bottom,
      #aeccfb,
      #c9d7fc,
      #dee3fd,
      #f1f1fd,
      #ffffff);
}

.welcomeToZubUTextContainer {
  background-color: white;
  margin: 10px;
  padding: 15px;
  border-radius: 20px;
  transition: 0.3s;
  box-shadow: 0 1px 3px #ccc;
  box-sizing: border-box;
  max-width: 700px;
}

.welcomeBlurb {
  font-size: small;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: small;
  color: rgb(46, 67, 106);
}

.welcome {
  font-size: x-large;
  font-weight: bolder;
  color: #3e8cab;
  padding-bottom: 2px;
}

.welcomeSubTitle {
  font-size: medium;
  padding-bottom: 8px;
  font-weight: bolder;
  color: rgb(46, 67, 106);
}

.headerTitleBlurb {
  font-weight: bold;
  font-size: small;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: small;
  color: rgb(46, 67, 106);
}

.headerContainer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 85px;
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #b8cff9;
}

.featuredImageContainerTop {
  height: 35px;
  background-image: linear-gradient(to top,
      #aeccfb,
      #c9d7fc,
      #dee3fd,
      #f1f1fd,
      #ffffff);
}

.featureImageContainer {
  margin-top: 0px;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.featureImage {
  width: 313px;
  height: 171px;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}

/**Course Search List **/

.courseSearchResultList {
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.lottieImageContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lottieImageHome {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 350px;
}

.lottieImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 400px;
}

.lottiDisclaimer {
  margin-top: 20px;
  max-width: 330px;
  font-size: medium;
}

.filterControlContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tipTitle {
  background-color: white;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1px;
  padding-right: 5px;
}

.tipText {
  font-size: smaller;
  width: 59%;
}

.recentRegistrationContainer {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-top: 70px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0 0px 1px #ccc;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom,
      #aeccfb,
      #c9d7fc,
      #dee3fd,
      #f1f1fd,
      #ffffff);
}

.loggedInWelcome {
  width: 100%;
  text-align: end;
  font-size: x-large;
  color: #3587a8;
  font-weight: bold;
  padding-left: 13px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 33px;
}

.recentRegTitle {
  width: 100%;
  font-size: medium;
  color: rgb(95, 95, 95);
  font-weight: bold;
  padding-left: 13px;
  padding-bottom: 5px;
}

/**end Course Search List **/

.footerWhatIsContainer {
  margin-top: 20px;
  width: 100%;
  background-image: linear-gradient(to bottom,
      #bababa,
      #cbcbcb,
      #dcdcdc,
      #ededed,
      #ffffff);
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerWhatIsContainerWrapper {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 699px) {
  .welcomeToZubUContainer {
    flex-direction: column;
  }

  .lottieImageHome {
    width: 200px;
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 599px) {
  .recentRegistrationContainer {
    margin-left: 0px;
    margin-right: 0px;
  }

  .welcome {
    font-size: medium;
    padding-bottom: 4px;
  }

  .welcomeSubTitle {
    font-size: small;
  }

  .welcomeBlurb {
    font-size: x-small;
  }

  .screen {
    margin-top: 55px;
  }

  .tipTitle {
    font-size: small;
  }

  .tipText {
    font-size: x-small;
    width: 60%;
  }

  .categoriesContainerTop {
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
  }

  .categoriesContainer {
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
  }

  .headerContainer {
    margin-top: 55px;
  }

  .welcomeToZubUContainer {
    margin-top: 55px;
  }

  .headerTitleBlurb {
    font-size: x-small;
  }

  .featuredImageContainerTop {
    height: 20px;
  }

  .featureImageContainer {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
  }

  .featureImage {
    width: 253px;
    height: 131px;
    margin: 10px;
    padding: 10px;
  }

  .lottieImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 270px;
  }

  .lottieImageHome {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px;
  }

  .lottiDisclaimer {
    padding-left: 10px;
    padding-right: 10px;
    font-size: small;
    max-width: 200px;
  }

  .loggedInWelcome {
    font-size: large;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }

  .recentRegTitle {
    font-size: small;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }
}

@media (max-width: 399px) {
  .recentRegistrationContainer {
    margin-top: 65px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .courseSearchResultList {
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
  }
}