.screen {
  width: 1005;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.welcomeTitle {
  font-weight: bold;
  padding-bottom: 20px;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: gray;
  cursor: pointer;
}

.description {
  padding-bottom: 10px;
  font-size: 14px;
  color: rgb(70, 70, 70);
}

.title {
  text-align: center;
}

.li {
  padding-bottom: 10px;
  font-size: 13px;
}

.image {
  height: auto;
  max-width: 100%;
}

.imageWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.imageTextContainer {
  cursor: pointer;
  font-weight: bold;
  background-color: white;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  transition: 0.3s;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid transparent;
  margin-bottom: 15px;
  color: rgb(65, 65, 65);
  min-width: 210px;
  max-width: 270px;
}

.imageTextContainer:hover {
  opacity: 0.9;
  border: 0.5px solid rgb(223, 218, 218);
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  color: rgb(4, 102, 130);
}

.infoBlurb {
  background-color: white;
  font-weight: bold;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  transition: 0.3s;
  box-sizing: border-box;
  text-align: justify;
  border-radius: 8px;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  margin-bottom: 15px;
  color: rgb(65, 65, 65);
}
