.container {
  border: 1px solid #ccc;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 30px;
  width: 60%;
  margin: auto;
}

.priceTotal {
  font-size: 17px;
  padding-bottom: 10px;
  color: rgb(203, 51, 51);
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 22px;
}

.creditsRemaining {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.priceCredits {
  padding-bottom: 6px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 23px;
  color: green;
}

.priceTaxes {
  padding-bottom: 3px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 23px;
}

.orderNumberContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.orderHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.showDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.link:active {
  color: #ccc;
}

@media screen and (max-width: 500px) {
  .container {
    width: 100%;
  }
}
