.phoneContentRow {
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.phoneContentInnerRow {
  display: flex;
}

.phoneContentTextAmend {
  font-size: 15px;
  white-space: pre-line;
  color: red;
}

.phoneContentTitleAmend {
  font-size: 16px;
  font-weight: bold;
  width: 30%;
  padding-bottom: 5px;
  display: flex;
  color: red;
}

.amendedWarning {
  color: red;
  display: flex;
  align-items: center;
}

.phoneContentNotesAmend {
  color: red;
  white-space: pre-line;
  padding-top: 1.5px;
  width: 70%;
}

.italic {
  font-style: italic;
  font-size: 12px;
}
