.infoContainer {
    width: 100%;
    text-align: justify;
    padding: 15px;
    display: flex;
    justify-content: center;
    background-color: #ccc;
    font-size: 14px;
}

.readOnlyTextContainer {
    min-height: 300px;
    width:100%;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    white-space: pre-wrap;
}