.imageRowContainer {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: "100vh";
  width: 100%;
}

.imageContainer {
  background-color: white;
  display: flex;
  flex: 1;
  min-width: 300px;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  transition: 0.3s;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid transparent;
  margin-bottom: 15px;
}

.imageContainer:hover {
  cursor: pointer;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgb(223, 218, 218);
  color: rgb(4, 102, 130);
  /* border: 1px solid rgba(42, 115, 148, 0.8);*/
}

.addPrice {
  padding-bottom: 15px;
  font-size: 25px;
  font-weight: bold;
}

.addDescription {
  padding-top: 20px;
  font-size: 15px;
  font-weight: bold;
}

.priceDiclaimerContainer {
  display: flex;
  flex: 1;
  width: 98%;
  padding: 30px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  align-content: center;
  font-size: 14px;
  background-color: white;
  transition: 0.3s;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  color: gray;
  text-align: justify;
}
