.dropContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 60%;
  font-size: 16px;
  min-height: 200px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 60%;
  font-size: 16px;
  min-height: 200px;
}

.title {
  color: black;
  padding-bottom: 10px;
}

.fileContainer {
  padding-bottom: 10px;
  width: 100%;
}

.fileName {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: rgb(42, 115, 155);
}

.fileName:hover {
  color: gray
}

.fileRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 500px) {
  .container {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .dropContainer {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sendContainer {
  width: 100%;
}