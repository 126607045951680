.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin: 0px;
  width: 100%;
  justify-content: center;
}

.rowSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin: 0px;
  width: 100%;
  justify-content: center;
}

.radioContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  box-shadow: 0 2px 3px gray;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  font-size: 22px;
  font-weight: bold;
  color: rgb(130, 130, 130);
  padding: 9px;
  padding: 15px;
  min-height: 140px;
  opacity: 0.8;
  width: 49%;
}

.radioSelectedContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  box-shadow: 0 2px 3px gray;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  font-size: 22px;
  font-weight: bold;
  color: black;

  width: 49%;
  min-height: 140px;

  border: 1px solid rgb(149, 201, 198);
  padding: 15px;
}

.radioFullContainer {
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  box-shadow: 0 2px 3px gray;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  font-size: 22px;
  font-weight: bold;
  color: black;
  padding: 9px;
  padding: 15px;
  min-height: 140px;
  width: 100%;
}

.radioContainer:hover {
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
}

.radioSelectedContainer:hover {
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
}

.radioFullContainer:hover {
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
}

.radioBtnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.radioDesc {
  padding-top: 5px;
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  width: 100%;
}

.center {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}