.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content {
  padding-left: 30px;
  padding-right: 20px;
}

.pdf {
  padding-top: 10px;
}

.courseTitle {
  text-decoration: underline;
  font-weight: bold;
}
