.screen {
  width: 100%;
}

.titleBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.headerButton {
  color: white;
  font-size: 21px;
  font-family: "Inter";
}

.headerIcon {
  visibility: visible;
}

.courseViewerContainer {
  width: 60%;
  margin: auto;
}

@media (max-width: 400px) {
  .headerButton {
    font-size: 16px;
    width: 10px;
  }

  .headerIcon {
    width: 0px;
    visibility: hidden;
  }
}

@media (max-width: 600px) {
  .courseViewerContainer {
    width: 100%;
  }
}

.gap {
  height: 85px;
}

@media (max-width: 499px) {
  .gap {
    height: 55px;
  }
}

.floatingContainer {
  position: fixed;
  right: 0px;
}

.princeRange {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  font-family: Inter;
  font-size: xx-large;
  color: green;
}

.courseDetails {
  padding-top: 0px;
  font-weight: bold;
  font-size: small;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 599px) {
  .floatingContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.question {
  font-size: 15px;
  padding-bottom: 13px;
}

.bubble {
  border-radius: 10px;
  padding: 10px;
  width: 250px;
  border: 0.5px solid #ccc;
  margin: 10px;
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}
