.summaryContainer {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 60px;
  min-height: 100px;
  width: 100%;
}

.container {
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.option {
  padding: 20px;
  border-bottom: 1px solid #ccc;
  align-items: center;
  display: flex;
  flex: 1;
}

.option2 {
  padding: 20px;
  align-items: center;
}

.radioDescItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 150px;
}