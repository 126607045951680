.screen {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 130px;
}

.buttonContainer {
  padding-top: 30px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 650px) {
  .form {
    width: 60%;
  }
}

@media (min-width: 800px) {
  .form {
    width: 40%;
  }
}

.logo {
  height: 42px;
  padding-right: 9px;
}

.logoText {
  height: 33px;
  align-items: center;
  padding-bottom: 2px;
}

.info {
  width: 90%;
  color: rgb(161, 161, 161);
  font-size: 13px;
  padding-bottom: 15px;
  text-align: justify;
}

.infobold {
  margin-top: 20px;
  width: 90%;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 15px;
  text-align: justify;
}

.title {
  width: 90%;
  color: black;
  font-size: 20px;
  font-family: Inter;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 20px;
}

.partnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 30px;
}

.link {
  color: rgb(42, 115, 155);
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
}

.pdf {
  display: flex;
  color: rgb(45, 45, 45);
  flex-direction: row;
  align-items: center;
  width: 90%;
  padding-top: 33px;
  padding-bottom: 12px;
}

.titleBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.formAndFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerButton {
  color: white;
  font-size: 21px;
  font-family: "Inter";
}

.headerIcon {
  visibility: visible;
}

@media (max-width: 499px) {
  .headerButton {
    font-size: 14px;
    width: 10px;
  }

  .headerIcon {
    width: 0px;
    visibility: hidden;
  }
}

.reCaptcha {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 92%;
}
