.container {
    display: flex;
    flex-direction: column;
}

.courseTitle {
    background-color: black;
    color: white;
    padding: 10px;
}

.playerAndChaptersContainer {
    display: flex;
    flex-direction: row;
}

.playerContainer {
    width: 70%;
    background-color: #f4f6f7;
    border-bottom-right-radius: 30px;
}

.player {
    height: 500px;
    background-color: black;
}

.chaptersContainer {
    width: 30%;
}

.empty {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: medium;
}

.courseContentChapterHeader {
    font-weight: bold;
    padding: 15px;
    display: flex;
    font-size: large;
    padding-bottom: 10px;
    padding-top: 20px;
}

.playingChapterTextContainer {
    padding: 20px;
    font-size: medium;
}

.playingChapterTextTitle {
    display: flex;
    flex-direction: row;
    font-size: medium;
    font-weight: bold;
}

.playingChapterTextDescription {
    display: flex;
    flex-direction: row;
    font-size: medium;
    padding-top: 10px;
    padding-bottom: 20px;
    font-weight: normal;
    margin-bottom: 20px;
}

.videoAccessContainer {}