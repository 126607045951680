/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 40px;
  color: #32325d;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  outline: none;
  background-color: white;
  font: inherit;
  padding: 10px 10px 6px 4px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(114, 113, 113);
}

.StripeElement:hover {
  cursor: pointer;
  outline: none;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.StripeElement--focus {
  border-bottom: 2px solid rgb(40, 14, 167);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
