.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 50px;
}

.title {
  font-family: "inter";
  color: #3E8CAB;
  font-size: large;
}

.image {
  height: 200px;
  padding-top: 10px;
  padding-right: 10px;
}

.columnImage {
  height: 200px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #3E8CAB;
  font-size: small;
}

@media (min-width: 499px) {
  .container {
    width: 40%;
    margin: auto;
  }
}