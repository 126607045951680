.itemContainer {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid 0.5px rgb(233, 233, 233);
  border-radius: 4px;
}

.column {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.maskedColumn {
  padding-left: 20px;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.itemContainer:hover {
  cursor: pointer;
}
