.pricingContainer {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.pricingItemContainer {
  border-radius: 10px;
  padding: 5px;
  width: 100%;
}

@media (min-width: 499px) {
  .pricingContainer {
    font-size: 14px;
    width: 60%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex: 50%;
    padding-right: 10px;
    flex-direction: row;
    padding-right: 5px;
    justify-content: space-between;
  }

  .pricingItemContainer {
    width: 50%;
  }
}

.sampleTitle {
  text-transform: uppercase;

  padding: 10px;
  padding-right: 0px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.registrationTitle {
  text-transform: uppercase;
  background: rgb(2, 0, 36);
  background: linear-gradient(15deg,
      rgba(2, 0, 36, 1) 0%,
      rgb(153, 184, 197) 79%,
      rgba(247, 254, 255, 1) 100%);

  padding: 10px;
  padding-right: 0px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.registrationContentMember {
  background-color: rgb(191, 251, 216);
  font-size: 13px;
  border-radius: 0 0 10px 10px;
  padding-top: 4px;
  min-height: 115px;
  border: 1px solid transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-bottom: 7px;
}

.registrationContent {
  background-color: rgb(196, 221, 230);
  font-size: 13px;
  border-radius: 0 0 10px 10px;
  padding-top: 4px;
  min-height: 115px;
  border: 1px solid transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-bottom: 7px;
}

.registrationContentRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.registrationContentTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 35%;
}

.registrationContentDescription {
  text-align: left;
  padding-left: 10px;
  width: 75%;
  word-wrap: break-word;
}

.pricingItemContainerSample {
  border: dashed 1px rgb(18, 128, 165);
  display: flex;
  height: 100%;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  min-height: 150px;
}

.registrationContent:hover {
  cursor: pointer;
  border: 1px dashed black;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.pricingItemContainerSample:hover {
  cursor: pointer;
  border: dashed 1px rgb(4, 11, 14);
}