.itemContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px;
}

.columnTitle {
  width: 100%;
  padding-right: 25px;
}

.columnDelete {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 5%;
}

.descColumn {
  width: 60%;
  padding: 0px;
}

.summaryRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.summaryPriceText {
  font-weight: bold;
  font-size: 15px;
  padding-bottom: 5px;
}

.priceRow {
  display: flex;
  flex-direction: row;
}

.deleteContainer {
  width: 10%;
}

.menuButton {
  color: red;
}

.summaryTextItalic {
  font-size: 15px;
  font-style: italic;
}

.summaryText {
  font-size: 15px;
}

.cap {
  text-transform: capitalize;
}
