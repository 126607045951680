.input {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 2px;
  width: 100%;
}

.label {
  padding: 0px;
  position: relative;
  left: 13px;
  top: 10px;
  z-index: 1;
  width: 100px;
  background-color: white;
  font-size: 12.5px;
  padding-left: 2px;
  color: rgb(114, 114, 114);
}

.labelLong {
  padding: 0px;
  position: relative;
  left: 13px;
  top: 10px;
  z-index: 1;
  width: 260px;
  background-color: white;
  font-size: 12.5px;
  padding-left: 2px;
  color: rgb(114, 114, 114);
}

.inputElement {
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  font-size: 15px;
  padding: 6px 10px 6px 4px;
  box-sizing: border-box;
  height: 60px;
  padding-left: 15px;
  width: 100%;
}

.inputElement:hover {
  outline: none;
  border: 1px solid black;
  cursor: pointer;
}

.styleTimeColor {
  color: green;
}

.datepickerPopper {
  z-index: 3 !important;
}