.container {
  padding: 15px;
  max-width: 399px;
}

.title {
  font-size: medium;
  font-weight: bold;
  padding-bottom: 4px;
}

.description {
  font-size: small;
  color: gray;
}

.disclaimer {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
  font-size: small;
}

.passcodeContainer {
  padding-top: 25px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.messageLinkPreview {
  font-size: small;
  color: gray;
  padding: 10px;

  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
