.itemContainer {
    width: 100%;
    height: 170px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: solid 0.5px rgb(233, 233, 233);
    border-radius: 4px;
}

.title {
    font-weight: bold;
}

.column {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media screen and (max-width: 599px) {
    .itemContainer {
        font-size: small;
    }

}