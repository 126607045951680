.cartItem {
  display: flex;
  flex-direction: column;
}

.itemData {
  width: 100%;
  padding-top: 0px;
  font-size: 12px;
}

.itemDataPrice {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  flex-direction: row;
  align-items: center;
}

.title {
  font-size: 16px;
  padding-bottom: 4px;
  font-weight: bold;
}

.amount {
  font-size: 14px;
}

.strikeAmount {
  font-size: 14px;
  padding-right: 5px;
  text-decoration: line-through;
}

.studentRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0px;
}

.studentColumn {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.studentTextBold {
  font-weight: bold;
  padding-right: 4px;
  font-size: 14px;
}

.studentContainer {
  padding-top: 5px;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.deleteButton {
  margin-left: 20px;
}
