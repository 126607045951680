.screen {
  display: flex;
  flex-wrap: wrap;
}

.fabButtons {
  display: flex;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.fabAndDateContainer {
  display: flex;
  width: 100%;
  padding-left: 13px;
  padding-right: 13px;
}

.dateCard {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dateItemCard {
  display: flex;
  height: 93px;
  padding-left: 13px;
  padding-right: 13px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  font-size: 22px;
  color: black;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dateTotalContainer {
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: rgb(63, 63, 63);
}

.analyticsTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(63, 63, 63);
}

.analyticsCourseTitleContainer {
  min-height: 20px;
}

.analyticsCourseTitle {
  font-size: 15px;
  padding-left: 28px;
  font-weight: bold;
  color: green;
}

.notFound {
  text-align: center;
  font-size: 13px;
}

.notFoundText {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
}
