.screen {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  background-image: linear-gradient(
    rgb(248, 248, 248),
    rgb(255, 255, 255),
    rgb(221, 224, 224)
  );
}

.form {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  margin-top: 130px;
}

.buttonContainer {
  padding-top: 30px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.title {
  font-size: 24px;
  text-align: left;
  width: 100%;
  padding-bottom: 50px;
  padding-right: 20px;
  padding-left: 20px;
}

.subTitle {
  font-size: 15px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

@media (min-width: 650px) {
  .form {
    width: 60%;
  }
}

@media (min-width: 800px) {
  .form {
    width: 40%;
  }
}

.titleBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.formAndFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
