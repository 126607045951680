.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signUpTypeImg {
  height: 150px;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid gray;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-size: small;
  font-weight: bold;
}

.hidden {
  height: 100px;
}

.textContainer {
  font-size: small;
  margin-top: 10px;
  text-align: center;
  padding-left: 10px;
}

@media (max-width: 499px) {
  .title {
    width: 150px;
    height: 50px;
  }

  .signUpTypeImg {
    height: 140px;
  }

  .hidden {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .textContainer {
    padding-bottom: 15px;
  }
}
