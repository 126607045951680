.itemContainer {
  width: 70%;
  display: flex;
  min-height: 150px;
  min-width: 500px;
  flex-direction: column;
  border: solid 0.5px rgb(222, 222, 222);
  border-radius: 4px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background-color: rgb(255, 252, 252);
  border-radius: 10px;
}

.column {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.replyContainer {
  border-radius: 5px;
  margin-top: 40px;
  font-size: 13px;
  padding-top: 10px;
  padding-right: 10px;
  width: 100%;
  padding-bottom: 10px;
  border: 1px dashed #ccc;
  background-color: rgb(250, 250, 250);
}

.replyTitle {
  padding-left: 10px;
  color: rgb(56, 56, 56);
  font-weight: bold;
}

.reply {
  padding-left: 10px;
  padding-top: 10px;
  font-size: 13px;
  font-weight: bold;
}

.nameAndReadFullContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.readMore {
  padding-top: 10px;
  text-align: right;
  font-size: 14px;
}

.simpleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.courseTitleAndRating{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  width: 65%;
}

.ratingFirstRow{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
}

.nameAndDateRating{
  display: flex;
  flex-direction: row;
}

.courseTitle {
  font-size: small;
  font-family: inter;
  color: rgb(45, 45, 45);
  font-weight: bold;
  padding-bottom: 5px;
}

.name {
  font-size: small;
  color: rgb(56, 56, 56);
  font-weight: bold;
  text-transform: capitalize;
  display: block;
  flex-direction: row;
  align-items: flex-end;
  text-align: right;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date {
  font-size: small;
  text-align: right;
  color: rgb(137, 137, 137);
  font-style: italic;
  display: block;
  width: 120px;
}

.comment {
  font-size: small;
  padding-top: 10px;
  width: 80%;
  overflow-wrap: break-word;
}

@media (max-width: 499px) {
  .itemContainer { 
    min-width: 70px;
    width: 97%;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .courseTitle {
    font-size: small;
  }
  
  .name {
    font-size: smaller;
    width: 55px;
  }
  
  .date {
    font-size: smaller; 
    width: 55px;
  }
  
  .comment {
    font-size: smaller;
    width: 290px;
  }
  
}
