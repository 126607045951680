html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  margin: 0;
  font-family: "Inter", "Open Sans", sans-serif;
  background: white;
}

div {
  font-family: "Open Sans", sans-serif;
}
