.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 500px;
}

.messageList {
  padding-top: 15px;
  height: 300px;
  overflow: auto;
  background-color: white;
}

.footerActions {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5px;
  padding: 10px;
  padding-top: 13px;
  background-color: rgba(247, 247, 247, 0.92);
}

.headerUserName {
  font-weight: bold;
  font-size: small;
}

.headerUserDesc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: smaller;
  color: gray;
}

.conversation {
  flex: 1px;
  background-color: white;
}

.footer {
  background-color: white;
}

.nothingSelected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  font-style: italic;
  padding: 20px;
}

.oneWayChatDisclaimer {
  padding: 20px;
  background-color: rgb(225, 225, 8);
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
