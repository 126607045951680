.pageContainerWhite {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.providerNoPadding {
  height: 90px;
}

.provider {
  height: 70px;
  padding: 15px;
  padding-left: 60px;
  padding-right: 60px;
}

.provider1 {
  height: 90px;
  padding: 15px;
  padding-left: 60px;
  padding-right: 60px;
}

.provider2 {
  height: 90px;
  padding: 15px;
  padding-left: 60px;
  padding-right: 60px;
}

.provider3 {
  height: 60px;
  padding: 15px;
  padding-left: 60px;
  padding-right: 60px;
}

.provider:hover {
  cursor: pointer;
}

.provider1:hover {
  cursor: pointer;
}

@media (max-width: 400px) {
  .headerButton {
    font-size: 16px;
    width: 10px;
  }

  .headerIcon {
    width: 0px;
    visibility: hidden;
  }

  .floatingTitle1 {
    font-size: 29px;
  }

  .floatingTitle2 {
    font-size: 18px;
  }

  .provider2 {
    height: 75px;
  }
}

@media (max-width: 599px) {
  .provider2 {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 17px;
    padding-right: 17px;
  }

  .provider1 {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 17px;
    padding-right: 17px;
  }

  .provider {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .textContainer {
    width: 100%;
  }

  .blurbContainer {
    width: 100%;
    padding-bottom: 40px;
  }

  .headerTextContainer {
    width: "100%";
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .headerBackground {
    padding: 10px;
    padding-top: 20px;
  }

  .headerImagePhone {
    max-width: 100%;
    height: auto;
    padding: 30px;
  }

  .pageContainer {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pageContainerLight {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.quote {
  width: 100%;
}
