.linkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
}

.title {
  font-weight: bold;
  padding-bottom: 5px;
}

.url {
  font-size: small;
  font-style: italic;
  width: 90%;
  word-wrap: break-word;

  box-shadow: 0 0px 1px black;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
}

.linkButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.url:hover {
  background-color: rgb(230, 230, 230);
}
