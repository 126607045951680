.load {
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgb(0, 136, 255);
  font-size: medium;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
}

.cirucularProgContainer {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load:hover {
  color: rgb(75, 85, 92);
  cursor: pointer;
}

.loadCard {
  height: 240px;
  width: 255px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  min-width: 175px;
  border-color: black;
  background-color: rgb(229, 227, 227);
  flex-direction: column;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;

  /** add shadow **/
  transition: 0.3s;
  box-shadow: 1px 1px gray;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: bold;
  font-size: larger;
}

.loadCard:hover {
  cursor: pointer;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
}

.loadCard:active {
  cursor: pointer;
  opacity: 0.5;
}

.cirucularProgContainerCard {
  height: 240px;
  width: 255px;
  display: flex;
  align-items: center;
  justify-content: center;
}
