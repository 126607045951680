.screen {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 130px;
}

.switchContainer {
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.switchText {
  font-size: small;
  font-style: italic;
  color: rgb(69, 69, 69);
}

.title {
  width: 90%;
  color: black;
  font-size: 20px;
  font-family: Inter;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 20px;
}

.buttonContainer {
  padding-top: 0px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.lottieImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 400px;
}

@media (min-width: 650px) {
  .form {
    width: 60%;
  }
}

@media (min-width: 800px) {
  .form {
    width: 40%;
  }
}

.paragraph {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: justify;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.paragraph2 {
  line-height: 25px;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.titleBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.formAndFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerButton {
  color: white;
  font-size: 21px;
  font-family: "Inter";
}

.headerIcon {
  visibility: visible;
}

@media (max-width: 499px) {
  .headerButton {
    font-size: 14px;
    width: 10px;
  }

  .headerIcon {
    width: 0px;
    visibility: hidden;
  }

  .lottieImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 270px;
  }
}

.maintenanceForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  height: 85%;
}

.messageBox {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 20px;
  color: white;
  background-color: rgb(131, 192, 95);
}
