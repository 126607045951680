.dialogContent {
  min-height: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.container {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  background-color: #ccc;
  font-size: medium;
  margin-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.documentLink {
  color: black;
}

.documentLink:hover {
  text-decoration: underline;
}

@media screen and (max-width: 499px) {
  .container {
    font-size: smaller;
  }

  .dialogContent {
    font-size: smaller;
  }
}

.centerContainer {
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}