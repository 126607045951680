.headerTitleBlurb {
    font-weight: bold;
    font-size: small;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: small;
    color: rgb(46, 67, 106);
}

@media (max-width: 599px) {
    .headerTitleBlurb {
        font-size: x-small;
    }
}