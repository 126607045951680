.card {
  width: 29%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px; /* 5px rounded corners */
  margin: 0px;
  min-width: 300px;
}

.sectionContainer {
  width: 100%;
  padding: 20px;
}

.sectionTitle {
  font-size: 14px;
  font-family: Inter;
  text-transform: uppercase;
  color: rgb(149, 149, 149);
}

.sectionContentContainer {
  padding-top: 10px;
}

.chargeContainer {
  font-size: 14px;
  margin-top: 10px;
}

.chargeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.amount {
  color: red;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}

/*** installments ****/

.installmentRow {
  padding-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.installmentInnerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
