.container {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  background-color: #ccc;
  font-size: 14;
}

@media screen and (max-width: 499px) {
  .container {
    font-size: smaller;
  }
}
