.itemContainer {
  width: 100%;
  min-width: 270px;
  max-width: 300px;
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}

.item {
  font-size: 13px;
  min-height: 254px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  transition: 0.3s;
  box-shadow: 0 2px 3px #ccc;
  padding: 0px;
  box-sizing: border-box;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
}

.imageContainerNoHover {
  width: 100%;
  min-height: 170px;
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: gray;
  border-bottom-width: 1px;
  border-bottom: 1px solid #ccc;
}

.imageContainer {
  width: 100%;
  min-height: 170px;
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: gray;
  border-bottom-width: 1px;
  border-bottom: 1px solid #ccc;
}

.imageContainer:hover {
  border: 2px dashed #ccc;
}

.itemRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  min-height: 65px;
}

.itemRowFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 10px;
}

.itemColumn {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 13px;
  min-height: 75px;
}

.lineContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.line {
  /* border-top: 0.5px solid #ccc;*/
  padding-bottom: 1px;
}

.statTitle {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: space-between;
  padding-right: 0px;
  width: 100%;
}

.title {
  padding-bottom: 1px;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.description {
  padding-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.itemActive {
  background: linear-gradient(rgba(41, 90, 122, 0.4), #eaebf0);
}

.itemPending {
  background: linear-gradient(rgba(31, 185, 36, 0.4), #eaebf0);
}

.itemHistory {
  background: linear-gradient(rgba(93, 93, 93, 0.4), #eaebf0);
}

.simpleRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  min-height: 39px;
}

.capacityContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.capacityRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.capacityTitle {
  width: 100%;
  padding-left: 15px;
  font-size: 12px;
  padding-top: 7px;
}

.eventContainer {
  color: rgb(63, 63, 63);
  display: flex;
  align-items: flex-end;
  font-size: 13px;
}

.total {
  margin-top: 5px;
  padding-bottom: 7px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}

.clickable {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clickable:hover {
  color: rgb(31, 85, 126)
}

.totalRevenu {
  color: green;
  font-size: 14px;
}

.totalHistory {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  height: 50px;
  font-size: 12px;
  background: linear-gradient(rgba(93, 93, 93, 0.4), #eaebf0);
}

.courseItemMenuContainer {
  display: flex;
  flex-direction: row;
  width: 365px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.courseItemMenuTitle {
  font-size: small;
  font-style: italic;
  width: 390px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;

  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 399px) {
  .courseItemMenuContainer {
    flex-direction: column;
    width: 200px;
  }

  .courseItemMenuTitle {
    width: 190px;
    line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.actionButtonsContainer {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: 7px;
  align-items: center;
  justify-content: flex-end;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
}

.dateOrTypeContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 3px;
  padding-left: 9px;
}

.advertisementContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  padding-bottom: 5px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}