.screen {
  width: 100%;
  margin-top: 15px;
}

.container {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.userContainer {
  margin-top: 110px;
  padding-left: 20px;
  padding-right: 20px;
}

.courseTitle {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: x-large;
  font-weight: bold;
  color: #3587a8;
}

.dateRow {
  display: flex;
  flex-direction: row;
  max-width: 200px;
  font-size: small;
  padding-bottom: 4px;
  border-radius: 10px;
}

.dateIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dateIconTitle {
  width: 40px;
  padding-bottom: 3px;
}

.dateTime {
  padding-left: 3px;
  width: 100%;
}

.dateTitle {
  min-width: 220px;
}

.dateElapse {
  flex: 1;
  font-size: small;
  display: flex;
  align-items: center;
}

.groupSeparator {
  padding-top: 30px;
  padding-bottom: 15px;
}

.notCheckedIn {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: italic;
  font-size: medium;
  font-weight: bold;
}

.checkedIn {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: italic;
  font-size: medium;
  color: green;
  font-weight: bold;
}

.resultNotFound {
  padding-top: 10px;
}

.titleBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.headerButton {
  font-size: large;
}

.lottieContainer {
  width: 100%;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.lottieImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 400px;
}

.lottieText {
  font: "open-sans";
  font-size: larger;
  max-width: 250px;
  color: #3587a8;
  font-weight: bold;
}

.shareButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.helpSwipe {
  font-size: small;
  font-style: italic;
  color: gray;
}

@media (max-width: 599px) {
  .container {
    padding-left: 7px;
    padding-right: 7px;
  }

  .userContainer {
    margin-top: 65px;
    padding-left: 7px;
    padding-right: 7px;
  }

  .lottieContainer {
    width: 100%;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .lottieImage {
    width: 200px;
  }

  .lottieText {
    padding-top: 20px;
    font-size: medium;
    text-align: center;
  }
}

@media (max-width: 499px) {
  .headerButton {
    font-size: 11px;
  }

  .rootHeaderButton {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 399px) {
  .recentRegistrationContainer {
    margin-top: 65px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .courseSearchResultList {
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
  }

  .courseTitle {
    font-size: medium;
  }

  .dateRow {
    font-size: small;
  }
}
