.bannerImage {
  height: 238px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.titleContainer {
  background-color: rgb(251, 251, 251);
  opacity: 0.8;
  padding: 3px;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(33, 122, 146);
  opacity: 1;
  align-items: center;
  padding: 3px;
  width: 100%;
  height: 42px;
}

.title {
  font: Inter;
  font-size: 12px;
  color: black;
  font-weight: bolder;
  text-transform: uppercase;
  width: 380px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.headline {
  font: Inter;
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  width: 370px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.callForAction {
  font: Inter;
  font-size: 10.5px;
  color: white;
  text-transform: uppercase;
  width: 370px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.button {
  color: white;
}

.logoContainer {
  position: relative;
  top: -115px;
  left: 10px;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  z-index: 3;
  overflow: hidden;
}

.logoContainerNone {
  z-index: -1;
  position: relative;
  top: 365px;
  left: 35px;
  background-color: white;
  width: 50px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.blurbs {
  display: flex;
  padding: 15px;
  margin-top: 20px;
  flex-direction: row;
  height: 130px;
  justify-content: space-around;
}

.blurbContainer {
  border-radius: 5px;
}
