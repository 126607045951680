.itemContainer {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: solid 0.5px rgb(233, 233, 233);
    border-radius: 4px;
  }
  
  .title {
    padding-left: 20px;
    max-width: 300px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .column1 {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  
  .column2 {
    flex: 2;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .column3 {
    flex: 3;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .column4 {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
   
  .thumbnail {
    height: 50px;
  }
  
  @media (max-width: 499px) {
    .column2 {
      display: none;
    }
    .column4 {
      display: none;
    }
  }
  