.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.listWrapper {
  padding-right: 5px;
  padding-left: 5px;
}

.listItem {
  display: flex;
  align-items: center;
  height: 50px;
}

@media (max-width: 499px) {
  .listItem {
    font-size: smaller;
  }
}
