.screen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: white;
}

.cards {
  width: 100%;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 140px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.list {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.item1-enter {
  opacity: 0;
}
.item1-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  animation-fill-mode: forwards;
}
.item1-exit {
  opacity: 1;
}
.item1-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
  animation-fill-mode: forwards;
}

.loadMoreContainer {
  padding-top: 0px;
}

.listContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 499px) {
  .cards {
    padding-left: 7px;
    padding-right: 7px;
    align-items: center;
    justify-content: center;
  }
}
