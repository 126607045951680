.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  padding-top: 20px;
  font-weight: bold;
  font-size: 14;
  color: #3e8cab;
}

.itemContainer {
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.itemSubContainer {
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5;
}

.iconTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textBold {
  font-weight: bold;
  font-size: 15px;
}

.text {
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.priceTotal {
  padding: 15px 0px 0px 0px;
  font-size: 15px;
  color: black;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.coupon {
  padding: 15px 0px 0px 0px;
  font-size: 13px;
  color: rgb(78, 165, 53);
  text-align: flex-start;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.couponValue {
  text-align: right;
  min-width: 100px;
}
