* {
  box-sizing: border-box;
}

.sectionTitle {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;

  align-items: center;
  justify-content: space-between;
  line-height: 40px;
  margin: auto;
  border-radius: 5px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 60%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}

.sectionContainer {
  font-size: 14px;
  width: 60%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex: 50%;
  padding-right: 10px;
  flex-direction: row;
  padding-right: 5px;
  justify-content: space-between;
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  width: 60%;
  margin: auto;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;
}

.rowTitle {
  font-weight: bold;
  padding-right: 10px;
}

.faqTitle {
  font-weight: bold;
  font-size: large;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 0px;
}

.faqAnswer {
  padding-top: 10px;
}

.warningText {
  font-size: medium;
  color: rgba(16, 25, 89, 0.667);
}

.needHelp {
  font-size: 23px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.helpImage {
  border-radius: 20px;
  margin: 20px;
  border: 0.5px solid #ccc;
}

@media screen and (max-width: 500px) {
  .sectionTitle {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .buttonContainer {
    width: 100%;
  }
  .sectionContainer {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    flex: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    text-align: justify;
  }

  .imgContainer {
    width: 100%;
  }
}

.link {
  color: rgb(0, 136, 255);
  text-decoration: none;
}

.link:hover {
  color: rgb(79, 79, 79);
}
