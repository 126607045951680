.sectionTitle {
  line-height: 40px;
  margin: auto;
  border-radius: 5px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 60%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 25px;
  padding-left: 20px;
  background: rgb(2, 0, 36);
  align-items: center;
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}

.sectionTitle:hover {
  cursor: pointer;
}

.regOptionsContainer {
  width: 60%;
  margin: auto;
  padding-top: 0px;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: justify;
}

.pricingContainer {
  width: 60%;
  margin: auto;
  padding-top: 0px;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  text-align: justify;
}
.icon {
  padding-right: 15px;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 500px) {
  .pricingContainer {
    width: 100%;
  }
  .regOptionsContainer {
    width: 100%;
  }
  .sectionTitle {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.currentProfessor {
  padding-top: 10px;
  padding-bottom: 10px;

  font-weight: bold;
  color: rgb(124, 119, 119);
}
