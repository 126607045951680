.targetTitle {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}

.container {
  margin-top: 10px;
}

.carouselContainer {
  border: 0px;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 15px;
}

.headlineContainer {
  padding-top: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.row {
  display: flex;
}

.locationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.locationSelectorContainer {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-height: 55px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.locationContainer {
  padding-top: 10px;
}

.locationIconText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.locationRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
