.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.containerSelect {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  margin: auto;
  text-align: right;
}

.containerSelectFull {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  margin: auto;
  text-align: right;
}

.buttonGroup {
  background-color: white;
}

.title {
  padding-bottom: 4px;
  font-weight: bold;
}

.titleSelect {
  padding-bottom: 4px;
  padding-right: 20px;
  width: 100%;
}

.price {
  padding-top: 5px;
  font-weight: bold;
  color: rgb(187, 32, 32);
}

@media screen and (max-width: 500px) {
  .containerSelect {
    width: 100%;
  }
}

.limitButton {
  width: 100px;
}
