.form {
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
}

.card {
  width: 100%;
  min-height: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  margin: 0px;
  margin-top: 10px;
}

.sectionContainer {
  width: 100%;
  padding: 20px;
}

.sectionTitle {
  font-size: 14px;
  font-family: Inter;
  text-transform: uppercase;
  color: rgb(149, 149, 149);
}

.sectionContentContainer {
  padding-top: 10px;
}

.chargeContainer {
  font-size: 15px;
  margin-top: 10px;
}

.chargeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 350px;
}

.amount {
  color: red;
}

.courseTitle {
  color: rgb(34, 102, 161);
  margin-top: 10px;
  margin-bottom: 10px;
}

.regOption {
  font-size: 14px;
  padding-bottom: 7px;
  font-weight: bold;
  margin-bottom: 10px;
}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.eventContainer {
  margin-top: 25px;
  font-size: 14px;
  border: 0.5px solid rgb(222, 221, 221);
  border-radius: 5px;
  padding: 13px;
}

.eventDate {
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.eventDescription {
  margin-left: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: rgb(59, 59, 59);
  opacity: 0.8;
  width: 100%;
}

.courseTitleEvent {
  color: rgb(34, 102, 161);
}

/*
@media (min-width: 599px) {
  .form {
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
*/

.eventName {
  font-weight: bold;
}

.refundEventContainer {
  display: block;
}

.refundSummaryTitle {
  margin-top: 10px;
  margin-left: 18px;
}

.eventReason {
  margin-top: 2px;
  margin-left: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: rgb(59, 59, 59);
  opacity: 0.8;
  width: 100%;
}
