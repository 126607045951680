
.raterContainer {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

.raterTitle {
    padding-bottom: 5px;
    text-transform: uppercase;
}

.container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    flex: 1;
  }
  
.subContainer {
    display: flex;
    flex-direction: row;
}

.title {
    font-size: 16px;
    padding-bottom: 5px;
    font-weight: bold;
    width: 100%;
}

.distributionContainerFull {
    padding-top:20px;
    padding-bottom: 40px;
    width: 100%;
}

.distributionContainer {
    padding-top:20px;
    padding-bottom: 40px;
    width: 70%;
}

.row { 
    display: flex; 
    flex-direction: row; 
    align-items: center
}

.textLarge {
    font-size: 45px;
    font-weight: bold;
}

.textSmall {
    font-size: small;
    color: gray;
}


.ratingNumber {
    width:110px;
    min-width: 110px;
}

.ratingProgress {
    width:80%;
    display:flex;
    flex-direction: column;
}

.loadMoreContainer {
    padding-top: 25px;  
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.rating { 
    padding-left: 2px; 
}

.ratingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bar {
    margin-left: 10px;
    width: 300px;
    min-width: 300px;
    height: 10px;
    background-color: #ccc;
    border-radius: 5px;
  }

.progress {
    width:10px;  
    height: 10px;
    background-color: #ff6700;
    border-radius: 5px;
}

@media (max-width: 499px) {
    .distributionContainer {
        width:97%;
    }

    .distributionContainerFull {
        width:97%;
    }

    .textLarge{
        font-size: 30px;
    }

    .ratingNumber {
        width:70px;
        min-width: 70px;
    }

    .ratingProgress {
        width:80%;
    }

    .bar {
        width: 150px;
        min-width: 150px;  
      }
}


@media (max-width: 360px) {
    .bar {
      width: 130px;
      min-width: 130px;  
    }
  }
  

.courseCartRatingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ratingCount{
    color: rgb(114, 114, 114);
    font-size: small;
}

.ratingScore {
    padding-left: 5px;
    padding-right: 5px;
    font-size: small;
    font-weight: bold;
    color:#c44f01;
}

@media (max-width: 599px) {
    .ratingCount{
        font-size: x-small;
    }

    .ratingScore {
        padding-left: 2px;
        padding-right: 2px;
        font-size: x-small;
    }
}