:root {
  --aa-base-z-index: 3000;
  --aa-font-size: 17px;
}

.aa-Form {
  border-radius: 23px;
}

.aa-DetachedSearchButton {
  border-radius: 23px;
}

.aa-PanelLayout {
  z-index: 300;
  background-color: white;
}

@media (max-width: 599px) {
  :root {
    --aa-font-size: 14px;
  }

  .aa-DetachedSearchButton {
    height: 35px;
    padding-left: 0px;
  }
}
