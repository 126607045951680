* {
  box-sizing: border-box;
}

.screen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: white;
}

.button {
  color: black;
}

.list {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  overflow-y: hidden;
}

.form {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 2%;
  overflow: hidden;
}

.title {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: center;
}

.sectionTitle {
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 40px;
  margin: auto;
  border-radius: 5px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 60%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}

.description {
  font-size: 16px;
  padding-bottom: 20px;
}

/* Container for flexboxes */
.row {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

/* Create four equal columns */
.column {
  flex: 25%;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
}

.column1 {
  flex: 25%;
  padding-top: 0px;
  padding-right: 5px;
  padding-left: 0px;
  padding-bottom: 0px;
}

.column2 {
  flex: 25%;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 5px;
  padding-bottom: 0px;
}

.sideBySide {
  display: flex;
  padding-bottom: 0px;
  width: 60%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.switchInputContainer {
  display: flex;
  width: 97%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .column {
    flex: 50%;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 500px) {
  .column {
    flex-direction: column;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .row {
    padding-bottom: 0px;
    flex-direction: column;
  }
  .sideBySide {
    width: 100%;
  }

  .sectionTitle {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.pricingContainer {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  text-align: justify;
}

.legalContainer {
  border: solid 0.5px rgb(202, 197, 197);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 23px;
  text-align: justify;
  margin-bottom: 20px;
}

@media (min-width: 499px) {
  .pricingContainer {
    font-size: 14px;
    width: 60%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex: 50%;
    padding-right: 10px;
    flex-direction: row;
    padding-right: 5px;
    justify-content: space-between;
  }
}

p {
  font-size: 13px;
  color: rgb(61, 61, 61);
  margin: 7px;
  font-style: italic;
  text-align: justify;
  display: block;
  width: 100%;
}

.icon {
  padding-right: 15px;
}

.rowIt {
  display: flex;
  flex-direction: row;
}

.spanParagraph {
  width: 300px;
  padding-right: 15px;
  display: block;
  font-size: 12px;
  padding-bottom: 10px;
}

.legalSrollContainer {
  border: 1px solid gray;
  width: 100%;
  height: 400px;
  padding: 10px;
  font-size: 13px;
  color: gray;
  border-radius: 3px;
  overflow: scroll;
  text-align: justify;
}
