.copyContainer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  
}

.titleContainer {
  width: 100%;
  align-items: center;
  padding: 15px;
  display: flex;
  background-color: rgb(238, 238, 238); 
}

.row{
  display:flex;
  flex-direction: row;
  align-items: center;
}