.label {
  padding: 0px;
  position: relative;
  left: 13px;
  top: 10px;
  z-index: 10;
  width: 100px;
  background-color: white;
  font-size: 12.5px;
  padding-left: 2px;
  color: rgb(114, 114, 114);
}

.currencyContainer {
  padding-left: 13px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  box-sizing: border-box;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  align-items: center;
  padding-right: 10px;
}

.currencyContainerError {
  padding-left: 13px;
  border: 1px solid red;
  border-radius: 5px;
  background-color: white;
  box-sizing: border-box;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  align-items: center;
  padding-right: 10px;
}

.currency {
  outline: none;
  font: inherit;
  border: 0;
  width: 90%;
}

.currency:focus {
  outline: none;
}

.currencyMargin {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flag {
  border-radius: 5px;
  height: 40px;
}

.refund {
  outline: none;
  font: inherit;
  border: 0;
  height: 35px;
  font-size: 13px;
  width: 70px;
}

.refundContainer {
  margin-left: 3px;
  padding-left: 3px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: white;
  box-sizing: border-box;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 38px;
  align-items: center;
}

.refundContainerError {
  padding-left: 3px;
  border: 1px solid red;
  border-radius: 3px;
  background-color: white;
  box-sizing: border-box;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 37px;
  align-items: center;
}
