.largeModal {
  position: fixed;
  width: 95%;
  height: 100%;
  left: 2.5%;
  top: 0%;

  z-index: 1500;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
  box-shadow: 0 2px 7px rgb(5, 5, 5);
  padding-bottom: 0px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0px 0px;
}

.smallModal {
  position: fixed;
  width: 70%;
  height: 250px;
  left: 15%;
  top: 30%;

  z-index: 1500;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
  box-shadow: 0 2px 7px rgb(5, 5, 5);
  padding: 0px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media (min-width: 600px) {
  .smallModal {
    width: 500px;
    left: calc(50% - 250px);
  }

  .largeModal {
    width: 95%;
    left: 2.5%;
  }
}

.modalOpen {
  animation: openModal 0.04s ease-out forwards;
}

.modalClose {
  animation: closeModal 0.04s ease-out forwards;
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 1;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    transform: translateY(60%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
