.screen {
  width: 100%;
}

.headerBackground {
  background-image: url("../../assets/images/welcome/back.png");
  background-repeat: no-repeat;
  margin-top: 85px;
  padding: 30px;
  padding-top: 60px;
  padding-bottom: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #e0f4fc;
  background-size: cover;
}

.headerTextContainer {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 60px;
  flex: 1;
}

.headerSpaceBetween {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.headerNoSpaceBetween {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.headerImagePhone {
  max-width: 90%;
  padding: 0px;
  height: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.headerImagePhoneProvider {
  max-width: 70%;
  padding: 0px;
  height: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.background {
  background-image: url("../../assets/images/welcome/back3.png");
  background-repeat: no-repeat;
  margin-top: 85px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e0f4fc;
}

.pageContainerLight {
  background: rgb(199, 214, 221);
  background: linear-gradient(
    349deg,
    rgba(199, 214, 221, 1) 0%,
    rgba(255, 255, 255, 1) 0%,
    rgba(247, 254, 255, 1) 12%,
    rgba(235, 246, 248, 1) 100%
  );
  padding-top: 80px;
  padding-bottom: 80px;
}

.pageContainerWhite {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.centerTitleContainer {
  display: flex;
  flex-direction: row;
}

.textContainer {
  padding: 20px;
  width: 60%;
}

.rightImageContainer {
  text-align: center;
}

.rightImageContainer2 {
  text-align: center;
}

.floatingTitle1 {
  padding-bottom: 20px;
  font-family: Inter;
  font-size: 34px;
  /* text-transform: uppercase;*/
  color: rgb(19, 19, 19);
}

.floatingTitle2 {
  padding-bottom: 15px;
  font-family: Inter;
  font-size: 20px;
  color: rgb(41, 41, 41);
}

.floatingTitle3 {
  padding-bottom: 35px;
  font-size: 15px;
}

.floatingTitle4 {
  padding-bottom: 60px;
  font-family: Inter;
  font-size: 34px;
  color: rgb(19, 19, 19);
  text-align: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.blubsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.blurbContainer {
  padding-left: 25px;
  padding-right: 25px;
  width: 25%;
}

.blurbContainerFull {
  display: flex;
  justify-content: center;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  text-align: center;
}

.blurbTitle {
  padding-top: 15px;
  padding-bottom: 12px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: rgb(56, 56, 56);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.blurbDescription {
  color: rgb(54, 54, 54);
  font-size: 14px;
}

.blurbPriceDescription {
  color: rgb(43, 43, 43);
  font-size: 16px;
  padding: 20px;
}

.loginButton {
  color: black;
  font-size: 19px;
  font-family: Inter;
}

.titleBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.benefitImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.benefitImage {
  width: 200px;
  text-align: center;
}

.headerButton {
  font-size: large;
}

.headerIcon {
  visibility: visible;
}

.brand {
  text-transform: none;
}

.launch {
  font-weight: bold;
  color: black;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 400px) {
  .floatingTitle1 {
    font-size: 29px;
  }

  .floatingTitle2 {
    font-size: 18px;
  }
}

@media (max-width: 499px) {
  .headerButton {
    font-size: 11px;
  }

  .rootHeaderButton {
    padding-left: 0px;
    padding-right: 0px;
  }

  .headerIcon {
    width: 0px;
    visibility: hidden;
  }

  .headerBackground {
    background-image: url("../../assets/images/welcome/back_mobile.png");
    margin-top: 55px;
  }

  .bigToothImage {
    max-width: 100%;
    height: auto;
  }

  .rightImageContainer {
    display: none;
  }

  .background {
    margin-top: 55px;
  }
}

@media (max-width: 599px) {
  .textContainer {
    width: 100%;
  }

  .blurbContainer {
    width: 100%;
    padding-bottom: 40px;
  }

  .headerTextContainer {
    width: "100%";
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .headerBackground {
    padding: 10px;
    padding-top: 20px;
  }

  .headerImagePhone {
    max-width: 100%;
    height: auto;
    padding: 30px;
  }

  .pageContainer {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pageContainerLight {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.paraBlurbTitle1 {
  padding-bottom: 20px;
  font-family: Inter;
  font-size: 34px;
  /* text-transform: uppercase;*/
  color: rgb(19, 19, 19);
}

.paraBlurbTitle2 {
  padding-bottom: 20px;

  font-size: 16px;
  /* text-transform: uppercase;*/
  max-width: 600px;
  color: rgb(19, 19, 19);
}
