.center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
}

.image {
    width: 70px;
}

.disclaimer {
    font-style: italic;
    font-size: small;
    padding-bottom: 15px;
}