.phoneContentText {
  font-size: 15px;
  white-space: pre-line;
}

.phoneContentRow {
  padding-left: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.phoneContentTextAmend {
  font-size: 15px;
  white-space: pre-line;
  color: red;
}
