.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0px;
  margin-bottom: 30px;
}

@media (min-width: 499px) {
  .container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-top: 0px;
    margin-bottom: 30px;
  }
}

.spanParagraph {
  width: 300px;
  padding-right: 15px;
  display: block;
  font-size: 12px;
  padding-bottom: 10px;
}

.boxContainer {
  background-color: rgb(228, 228, 228);
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 20px;
}

.installmentsTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding-bottom: 10px;
}

.installmentsDesc {
  padding-bottom: 10px;
  text-align: justify;
}

.simulatorTitle {
  display: flex;
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 10px;
  justify-content: center;
  border-top: 1px solid #ccc;
  font-weight: bold;
}

.simulatorResult {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  align-items: center;
  font-size: medium;
}
