* {
  box-sizing: border-box;
}

.sectionTitle {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;

  align-items: center;
  justify-content: space-between;
  line-height: 40px;
  margin: auto;
  border-radius: 5px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 60%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}

.sectionContainer {
  font-size: 14px;
  width: 60%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex: 50%;
  padding-right: 10px;
  flex-direction: row;
  padding-right: 5px;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;
}

.rowTitle {
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .sectionTitle {
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .buttonContainer {
    width: 100%;
  }
  .sectionContainer {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    flex: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    text-align: justify;
  }
}
