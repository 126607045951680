.container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 550px;
  margin: auto;
  border-radius: 5px;
  background: rgb(235, 236, 238);
  margin-top: 10px;
  margin-bottom: 10px;
}

.container:hover {
  box-shadow: 0 8px 16px 0 rgba(24, 46, 102, 0.5);
}

.chartContainer {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 10px;

  line-height: 40px;
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-family: Inter;
  text-transform: uppercase;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;

  background: rgb(2, 0, 36);
  background: linear-gradient(
    15deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(136, 174, 190, 1) 55%,
    rgba(247, 254, 255, 1) 100%
  );
}
