.iconButton {
  border-width: 0px;
  padding: 0px;
}

.button {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: black;
  border: 0px;
  outline: none;
}

.disabled {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #ccc;
  border: 0px;
  outline: none;
}

@media (min-width: 499px) {
  .button {
    font-size: 19px;
  }
  .disabled {
    font-size: 19px;
  }
}

.button:hover {
  color: rgb(8, 49, 77);
  cursor: pointer;
}

.textButton {
  color: rgb(0, 136, 255);
  font-size: medium;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.textButton:active {
  color: #ccc;
}

/** for FormButton Only*/
.buttonContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fullButtonContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 500px) {
  /** for FormButton Only*/
  .buttonContainer {
    width: 100%;
  }
}
