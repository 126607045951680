.container {
  width: 100%;
}

.spanParagraph {
  width: 300px;
  padding-right: 15px;
  display: block;
  font-size: 12px;
  padding-bottom: 10px;
}
