.navigationItem {
  margin: 0;
  display: flex;
  height: 100%;
  width: auto;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
}

.navigationItem a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  padding-right: 19px;
  padding-left: 19px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  border-bottom: 4px solid transparent;

  text-transform: uppercase;
  color: black;
}

.navigationItem a:hover {
  /*border-bottom: 4px solid #12425f;*/

  /*background-color: white;*/
  opacity: 0.7;
  color: gray;
}

.navigationItem a:active,
.navigationItem a.active {
  /*border-bottom: 4px solid #426e8a;*/

  /*background-color: rgb(198, 206, 212);*/
  color: black;
}

@media (min-width: 500px) {
  .mobileOnly {
    display: none;
  }
}

.smallFont {
  font-size: 10px;
  padding-right: 0px;
  padding-left: 0px;
}

.eventListContainer {
  top: 56px;
  right: 0px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  opacity: 1;
  display: block;
  padding: 10px;
  padding-left: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
